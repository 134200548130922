import { React, useEffect, useState } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import Logo from "../../assets/images/cftLogo.svg"
import loginImg from "../../assets/images/loginImg.png"
import calendarIcon from "../../assets/images/calendarIcon.svg"
import commentIcon from "../../assets/images/commentIcon.svg"
import goalIcon from "../../assets/images/goalIcon.svg"

import axios from "axios";
import { BASE_URL } from "../../config";
import moment from "moment";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
const SignUp = () => {
  const [userForm, setUserForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    dob: "",
    coupon: "",
    roles: "user"
  });
  const [loading, setLoading] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const [showConfPass, setShowConfPass] = useState(false);
  const handleLoginSubmit = async (event) => {
    if (!userForm?.firstName || !userForm?.lastName || !userForm?.email || !userForm?.password || !userForm?.confirmPassword || !userForm?.dob) {
      setShowError(true);
      setTimeout(function () {
        setErrorMessage('');
        setShowError(false);
      }, 4000);
      return;
    }

    if (userForm.password !== userForm.confirmPassword) {
      setPasswordMatch(false);
      return;
    } else {
      setPasswordMatch(true);
    }

    setError("");
    setLoading(true);

      const response=await axios.post(`${BASE_URL}/auth/signup`,userForm)
console.log(response,"-----")
      if(response.status===200 && response.data.isOk===true){
        console.log("fucntions here ")
        // setTimeout(() => {
          // setLoading(false);
              navigate(`/sign-up/success/${userForm.firstName}`);
            // }, 200);
      }else if(response.status===200 && response.data.isOk===false){
        console.log("fucntion here : ",response)
        setErrorMessage("you are already register !");
            setTimeout(() => {
              setError("");
            }, 3000);
            setLoading(false);
      }else if(response.status===200 && response.data.success===false){
        setErrorMessage("Inavalid coupon !");
            setTimeout(() => {
              setError("");
            }, 3000);
            setLoading(false);
      }else{
        console.log("here :  ")
        setError(response?.data?.message);
            setTimeout(() => {
              setError("");
            }, 3000);
            setLoading(false);
          // }
      }
    // axios({
    //   url: `${BASE_URL}/auth/signup`, //your url
    //   method: 'POST',
    //   data: userForm
    // }).then((res) => {
    //   console.log(res,"====")
    //   setLoading(false);
    //   setTimeout(() => {
    //     navigate(`/sign-up/success/${userForm?.firstName}`);
    //   }, 200);
    // }).catch((err) => {
    //   if (err?.response?.data?.message) {
    //     console.log("res.data",err?.response?.data?.message)
    //     setError(err?.response?.data?.message);
    //     setTimeout(() => {
    //       setError("");
    //     }, 3000);
    //   }
    //   setLoading(false);
    // });
  };

  const handleConfirmPasswordChange = (e) => {
    setUserForm({ ...userForm, confirmPassword: e.target.value });
    if (e.target.value !== userForm.password) {
      setPasswordMatch(false);
    } else {
      setPasswordMatch(true);
    }
  };

  const validateEmails = (e) => {
    setUserForm({ ...userForm, email: e.target.value });

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(e.target.value.trim())) {
        setShowError('Invalid email address.');
      setTimeout(function () {
        setErrorMessage('');
        setShowError(false);
      }, 4000);
      return false;
    }
    return true;
  };

  useEffect(() => {
    let isLogin = localStorage.getItem('auth-token');
    if (!isLogin && !(location.pathname === '/' || location.pathname === '/sign-up')) {
      navigate('/');
    } else if (isLogin && location.pathname === '/') {
      navigate('/dashboard');
    }
  }, [location]);

  const [showSelect, setShowSelect] = useState(false);
  const [coupon, setcoupon] = useState({ coupon: '' });
    const toggleSelectVisibility = () => {
    setShowSelect(!showSelect);
  };

  return (
    <>
     <div className="sign-in-default sign-in-default-v1 sign-up-page">
        {/* <div className="bg-icons" /> */}
        <div className="container">
          <div className="form-box-outer">
            <div className="content-block">
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>
              <h1>CFT is your all-in-one <br /> fitness solution</h1>
              <p>CFT is a fitness tracker-based App that provides all the necessary components to help individuals and employees become healthy.</p>
              <ul>
                <li><img src={calendarIcon} /> Monthly Calendar</li>
                <li><img src={goalIcon} /> Summary And Goals</li>
                <li><img src={commentIcon} /> Healthy content</li>
              </ul>
              <span className="join-us">Join us today</span>

            </div>

            <div className="form-box">
              <h2>Signup</h2>
              <div className="flex flex-col sm:flex-row gap-2">
                <div className="input-box">
                  <label>First Name</label>
                  <input
                      value={userForm?.firstName}
                      onChange={(e) => setUserForm({ ...userForm, firstName: e.target.value })}
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder="First Name*"
                    />
                </div>
                <div className="input-box">
                  <label>Last Name</label>
                  <input
                    value={userForm?.lastName}
                    onChange={(e) => setUserForm({ ...userForm, lastName: e.target.value })}
                    id="lastName"
                    name="lastName"
                    type="text"
                    placeholder="Last Name*"
                  />
  
                </div>
              </div>
              <div className="input-box">
                <label>Email Address</label>
                <input
                  value={userForm?.email}
                  onChange={validateEmails}
                  id="email-address"
                  type="email"
                  required
                  placeholder="Email address*"
                />
                 <span className="error">{showError}</span>

              </div>
              <div className="input-box">
                <label>Date of Birth</label>
                <input
                value={userForm?.dob}
                onChange={(e) => setUserForm({ ...userForm, dob: e.target.value })}
                id="password"
                name="password"
                type="date"
                autoComplete="current-password*"
                max={moment().subtract(10, 'years').format('YYYY') + '-12-31'}
                min={moment().subtract(100, 'years').format('YYYY') + '-12-31'}
                placeholder="DOB*"
              />
              </div>
              <div className="input-box">
              <label>Password</label>
              <div className="relative">
              <input
                  value={userForm?.password}
                  onChange={(e) => setUserForm({ ...userForm, password: e.target.value })}
                  id="password"
                  type={!showPass?'password':'text'}
                  required
                  placeholder="Password*"
                />
                   <span className="input-icon" onClick={()=>{setShowPass(!showPass)}} >
                  {!showPass?<EyeSlashIcon className="h-5 w-5 text-gray-300" />: <EyeIcon className="h-5 w-5 text-gray-300" />}
                </span>
                </div>
              </div>
              <div className="input-box">
                <label>Confirm Password</label>
                <div className="relative">
                <input
                  value={userForm?.confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  id="confirmPassword"
                  type={!showConfPass?'password':'text'}
                  required
                  placeholder="Confirm Password*"
                />
                 <span className="input-icon" onClick={()=>{setShowConfPass(!showConfPass)}} >
                  {!showConfPass?<EyeSlashIcon className="h-5 w-5 text-gray-300" />: <EyeIcon className="h-5 w-5 text-gray-300" />}
                </span>
                </div>
              </div>
              {!passwordMatch && <p className="text-red-500 my-2 text-sm">Passwords does not match</p>}
              <div className="input-box">

                <label id="check" className="cursor-pointer">
                  <input
                    className=""
                    type="checkbox"
                    value="check"
                    onChange={toggleSelectVisibility}
                  />
                  Add Coupon
                  </label>
                  {showSelect && (
                    <>
                    <input
                      value={userForm?.coupon}
                      onChange={(e) => setUserForm({ ...userForm, coupon: e.target.value })}
                      id="coupon"
                      name="coupon"
                      type="text"
                      placeholder="Coupon Code"
                    />
                    <div className="green-clr text-xs mt-1">Apply Coupon code if you have any.</div>
                  </>
                )}

              </div>
 
              <div className="bottom-text">
                <span className="error">{errorMessage}</span><br/>
                {showError && <p className="text-red-500 my-2 text-xs">Fields with star (*) are required.</p>}
                By signing up you declare you have read and agreed with our <a href="https://www.contingentfitness.com/terms-and-conditions/">Terms and Conditions</a> and <a href="https://www.contingentfitness.com/privacy-policy/">Privacy Policy</a>
 
 
              </div>
 
              <div className="submit-box">
              <button onClick={() => handleLoginSubmit()} type="button" className="group relative flex w-40 justify-center rounded-md border border-transparent bg-[#4285F4] py-2 px-4 text-sm font-medium text-white hover:bg-[#4285F4] focus:outline-none">
                                    {loading ? (<div className="lds-facebook"><div></div><div></div><div></div><div></div><div></div></div>) : "Sign Up"}
                                </button>
              </div>
 
              <div className="bottom-text text-center black-clr">
                Have an account? <Link to="/">Log in</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>)
}
export default SignUp;