
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "../../config";

const CompUserEdit = ({ forumData, goBack, getUsersList }) => {

  useEffect(() => {
    const isAdmin = ['company'].includes(localStorage.getItem('roles'));
    if (!isAdmin) {
      window.location = window.location.origin + '/membership';
    }
  }, []);

  const [formData, setFormData] = useState({
    id: forumData._id,
    firstName: forumData.firstName,
    lastName: forumData.lastName,
    age: forumData.age,
    gender: forumData.gender,
    height: forumData.height,
    weight: forumData.weight,
    status: forumData.status
  });

  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [error, setError] = useState('');
  const[buthide,setButhide]=useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const updateUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!formData.firstName || !formData.lastName || !formData.age || !formData.gender || !formData.height || !formData.weight) {
      setShowError(true);
      setLoading(false);
      return;
    }

    try {
      await axios.put(`${BASE_URL}/user/update`, formData, {
        headers: {
          "x-access-token": localStorage.getItem('auth-token'),
        },
      });
      goBack();
    } catch (err) {
      setLoading(false);
    }
  };
  const uploadImage = (e) => {
    e.preventDefault();
    if (!profileImage) return;

    setLoading(true);

    const formData = new FormData();
    formData.append('profileImage', profileImage);

    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
        'Content-Type': 'multipart/form-data'
      },
      url: `${BASE_URL}/user/profile/uploadImage/${forumData._id}`,
      method: 'POST',
      data: formData
    }).then((res) => {
      setLoading(false);
      fetchProfileImage();
    }).catch((err) => {
      if (err?.response?.data?.message) {
        setError(err?.response?.data?.message);
        setButhide(false)
        setTimeout(() => {
          setError("");
        }, 3000);
      }
      setLoading(false);
    });
  };
  const fetchProfileImage = () => {
    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
      },
      url: `${BASE_URL}/user/profile/image/${forumData._id}`,
      method: 'GET',
      responseType: 'blob'
    }).then((res) => {
      const url = URL.createObjectURL(res.data);
      setImageURL(url)
    }).catch((err) => {
      if (err?.response?.data?.message) {
        setError(err?.response?.data?.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    });
  };
  const handleProfilePictureChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImageURL(URL.createObjectURL(e.target.files[0]));
      setButhide(true);
    }
  };
  const handleImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };
useEffect(()=>{
    fetchProfileImage();
},[])
  return (
    <div className="edit-user-page sign-in-default">
      <div className="container">
        <span className="close-btn" onClick={() => { goBack(); }}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </span>
        <div className="form-box-outer">
          <div className="form-box">
            {/* <h1>Edit User</h1> */}
            <form onSubmit={updateUser}>
              <div className="input-box col-span-2 profile-box">
                <div className="flex items-center gap-4">
                  <div className="relative" id="file">
                    {imageURL && <img src={imageURL} alt="Profile" />}
                    <div className="flex">
                      <input
                        type="file"
                        onChange={(e) => {
                          handleImageChange(e);
                          handleProfilePictureChange(e);
                        }}
                      />
                      <button onClick={uploadImage} className={`py-1 px-3 rounded-lg text-white text-xs bg-[#2995F7] border-none ${buthide  ? "" : "hidden"}`}>Confirm</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="input-box">
                <div className="heading">First Name</div>
                <input
                  value={formData.firstName}
                  onChange={handleChange}
                  name="firstName"
                  type="text"
                />
              </div>
              <div className="input-box">
                <div className="heading">Last Name</div>
                <input
                  value={formData.lastName}
                  onChange={handleChange}
                  name="lastName"
                  type="text"
                />
              </div>
              <div className="input-box">
                <div className="heading">Age</div>
                <input
                  value={formData.age}
                  onChange={handleChange}
                  name="age"
                  type="number"
                  min="1"
                  max="120"
                />
              </div>
              <div className="input-box radio-box">
                <div className="heading">Gender</div>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      checked={formData.gender === "male"}
                      onChange={handleChange}
                    />
                    Male
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      checked={formData.gender === "female"}
                      onChange={handleChange}
                    />
                    Female
                  </label>
                </div>
              </div>
              <div className="input-box">
                <div className="heading">Height</div>
                <input
                  value={formData.height}
                  onChange={handleChange}
                  name="height"
                  type="number"
                  min="1"
                  max="200"
                />
              </div>
              <div className="input-box">
                <div className="heading">Weight</div>
                <input
                  value={formData.weight}
                  onChange={handleChange}
                  name="weight"
                  type="number"
                  min="1"
                  max="200"
                />
              </div>
              <div className="input-box">
                <div className="heading">Status</div>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  className="bg-theme-blue autofill:bg-slate-800 relative block w-full border border-[#2d3d5c] px-3 py-2 text-gray-300 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="1" >Active</option>
                  {/* <option value="2" >Limited Access</option> */}
                  <option value="0" >Disable</option>
                </select>
              </div>
              {showError && <p className="text-red-500 my-2">* None of the fields should be empty</p>}
              <div className="submit-box">
                <button type="submit" disabled={loading}>
                  {loading && (
                    <svg aria-hidden="true" role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                    </svg>
                  )}
                  UPDATE
                </button>
                <button onClick={goBack} type="button" className="cancel-btn">
                  CANCEL
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompUserEdit;
