import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "../../config";
import Table from '../../components/table';
import axios from 'axios';
import moment from 'moment';
import DeletePopUp from '../../components/deletePopUp';
import NotifyPopUp from '../../components/notifyPopUp';
const SessionHistory = ({back}) => {

    useEffect(() => {
        let subscribedStatus = localStorage.getItem('subscribedStatus');
            
        // If subscribedStatus is falsy, redirect to membership page
        if (subscribedStatus == 'false') {
            window.location = window.location.origin + '/membership';
            return false;
        }
    });

    const navigate = useNavigate();
    const [rowHeaderData, setRowHeaderData] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        offset: 0,
        itemsPerPage: 10,
        totalCount: 0,
    });
    const [trainingSlots, setTrainingSlots] = useState([]);
    const [loading, setLoading] = useState([]);
    const[trainerName,setTrainerName]=useState();
    const[isActiveDeletePopup,setIsActiveDeletePopup]=useState(false)
    const[delUser,setDelUser]=useState();
    const[notifyPopup,setNotifyPopup]=useState(false)
    const getTrainingSlots = () => {
        setLoading(true);
        axios({
            headers: {
                "x-access-token": localStorage.getItem('auth-token')
            },
            url: `${BASE_URL}/user/training/slots`, //your url
            method: 'get',
        }).then((res) => {
            // console.log("userTaaining sloe rt",res?.data?.data);
            setTrainingSlots(res?.data?.data);
            setTrainerName(res?.data?.admin);
            // setNotifyPopup(true)
            setLoading(false);
        });
    };
    const userTableRowTitle = [
        { title: "Date", key: "date", formatData: (data) => moment(data).format('DD MMM YYYY') },
        { title: "Start Time", key: "startTime" },
        { title: "End Time", key: "endTime" },
    ];

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: adds smooth scrolling animation
        });
        setRowHeaderData(userTableRowTitle);
    }, []);

    useEffect(() => {
        getTrainingSlots();
    }, [pagination.page]);

    const getActionData = (data) => {
        return false;
    };
    const handeleDeletesession=(id ,data)=>{
setIsActiveDeletePopup(true);
    }
    const yes = ()=>{
        deleteSession(delUser);
      }
      const cancel = ()=>{
        setIsActiveDeletePopup(false);
      }
      const ok = ()=>{
        setNotifyPopup(false);
         
    }
      
      
      const deleteSession = async(formData) => {
     try {
        const response = await axios.post(`${BASE_URL}/user/training/delete`, {id:formData}, {
            headers: {
              "x-access-token": localStorage.getItem("auth-token"),
            },
        }); 
        if (response.data.success) {
              console.log("res",response.data.success);
            setIsActiveDeletePopup(false);
            getTrainingSlots(); 
            setNotifyPopup(true);
          }
        } catch (error) {
          console.error("Error adding Training package package:", error);
        }
      };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        
        return `${day}-${month}-${year}`;
      };
      const isCancelable = (date, startTime) => {
        // console.log("------",date,startTime)
        const now = new Date();
        const sessionDate = new Date(date);
        const sessionTime = moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm A').toDate();
        if (sessionDate < now) {
            return false;
        }

        const timeDiff = sessionTime.getTime() - now.getTime();
        const minutesDiff = Math.floor(timeDiff / (1000 * 60));

        return minutesDiff > 15;
    };

    return (
        <>
        {isActiveDeletePopup&&<DeletePopUp data={`sure you want to cancel ! `} yes={yes} cancel={cancel}/>}
        {notifyPopup&&<NotifyPopUp data={`Your request to cancel a training slot has been submitted. You will be notified via email once the request is accepted.! `}  ok={ok}/>}
            <div className=" md:mx-10">
                <div className="flex justify-between mx-2 py-8 md:my-0 md:mx-10">
                    <button onClick={() =>{ navigate('/Training');back(8)}} type="button" className="flex items-center bg-theme-blue-secondary text-gray-200 p-2.5 rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path fill-rule="evenodd" d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z" clip-rule="evenodd" />
                        </svg>
                    </button>
      
                </div>
                {/* <Table setPagination={setPagination} pagination={pagination} loading={loading} headerData={rowHeaderData} rowData={trainingSlots} getActionData={getActionData} /> */}
                

<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3 bg-gray-50 dark:bg-gray-800 text-center">
                    Date
                </th>
                <th scope="col" class="px-6 py-3 text-center">
                    Start Time
                </th>
                <th scope="col" class="px-6 py-3 text-center">
                    End Time
                </th>
                <th scope="col" class="px-6 py-3 text-center">
                    Trainer
                </th>
                <th scope="col" class="px-6 py-3 text-center">
                    Status
                </th>
                <th scope="col" class="px-6 py-3 text-center">
                    Action
                </th>
            </tr>
        </thead>
        <tbody>
            {trainingSlots?.map((da)=>(
            <tr class={da.cancelReq ? `border-b border-gray-200 dark:border-gray-700 bg-slate-200`:`border-b border-gray-200 dark:border-gray-700 `} key={da._id}>
                <td class="px-6 py-4 text-center" >
                {formatDate(da?.date)}
                </td>
                <td class="px-6 py-4 text-center">
                    {da?.time?.startTime}
                </td>
                <td class="px-6 py-4 text-center">
                    {da?.time?.endTime}
                </td>
                <td class="px-6 py-4 text-center">
                  {trainerName}
                </td>
                <td class="px-6 py-4  text-center" >
                    {isCancelable(da?.date,da?.time?.startTime) ? <div >
                        {da.cancelReq?<div className='pointer-events-none'>{da?.cancelByAdmin?"Canceled By Admin":"Cancel Req..."}</div>:
                       "In Process ...  "}
                  
           </div>:'Completed'}
                </td>
                    <td class="px-6 py-4 text-center">
                    {isCancelable(da?.date,da?.time?.startTime)?<div>
                    {da.cancelReq ?<button className='bg-[#27a500] border-0 p-2 text-white rounded pointer-events-none'> Requested</button>: <div onClick={()=>{handeleDeletesession();setDelUser(da._id)}}>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-12 w-5 justify-center">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg> */}
                  <button className='bg-[#186CED] border-0 text-white p-2 px-3 rounded'>cancel Request</button>
                  </div>
                  }
                  </div>:<button className='bg-[#27a500] border-0 p-2 text-white rounded pointer-events-none'> Completed</button>}
                    </td>
            </tr>))}
        </tbody>
    </table>
</div>

            </div>
        </>
    )
}
export default SessionHistory;