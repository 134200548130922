import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import DeletePopUp from "../components/deletePopUp";

const MemberShipManaged = () => {
  const [allSub, setAllSub] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [pakId, setPakId] = useState();
  const [pakName, setPakName] = useState();
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    title: "basic",
    content: "",
    benefits: [""],
    price: 0,
    type: "month",
    isActive: false,
  });

  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/allSub`, {
        headers: {
          "x-access-token": localStorage.getItem("auth-token"),
        },
      });
      setAllSub(response?.data?.data);
    } catch (error) {
      console.error("Error fetching membership:", error);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);
  const handleAddBenefit = () => {
    setFormData({
      ...formData,
      benefits: [...formData.benefits, ""],
    });
  };

  const handleRemoveBenefit = (index) => {
    setFormData({
      ...formData,
      benefits: formData.benefits.filter((benefit, i) => i !== index),
    });
  };


  // const handleBenefitChange = (index, value) => {
  //   const updatedBenefits = [...formData.benefits];
  //   updatedBenefits[index] = value;
  //   setFormData({
  //     ...formData,
  //     benefits: updatedBenefits,
  //   });
  //   if (value && index === formData.benefits.length - 1) {
  //     setFormData({
  //       ...formData,
  //       benefits: [...formData.benefits, ""],
  //     });
  //   }
  // };
  //////////////////////////
  // const handleBenefitChange = (index, value) => {
  //   if (value) {
  //     const updatedBenefits = [...formData.benefits];
  //     if (updatedBenefits.length <= index) {
  //       updatedBenefits.push(...new Array(index - updatedBenefits.length + 1).fill(''));
  //     }
  //     updatedBenefits[index] = value;
  //     setFormData({
  //       ...formData,
  //       benefits: updatedBenefits,
  //     });
  //   }
  // };
  const handleBenefitChange = (index, value) => {
    setFormData({
      ...formData,
      benefits: formData.benefits.map((benefit, i) => (i === index ? value : benefit)),
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = edit
        ? `${BASE_URL}/admin/updateSub/${pakId}`
        : `${BASE_URL}/admin/managedSub`;
      const response = await axios.post(url, formData, {
        headers: {
          "x-access-token": localStorage.getItem("auth-token"),
        },
      });
      if (response.data.success) {
        fetchPlans();
        setFormData({
          title: "",
          content: "",
          benefits: [""],
          price: 0,
          type: "month",
          isActive: false,
        });
        setAdd(false);
        setEdit(false);
      }
    } catch (error) {
      console.error("Error adding/editing membership package:", error);
    }
  };

  const yes = () => {
    handleDelete(pakId);
  };

  const cancel = () => {
    setIsDelete(false);
  };

  const handleDelete = async (Id) => {
    try {
      await axios.post(
        `${BASE_URL}/admin/deletePackage/${Id}`,
        {},
        {
          headers: {
            "x-access-token": localStorage.getItem("auth-token"),
          },
        }
      );
      fetchPlans();
      setIsDelete(false);
    } catch (error) {
      console.error("Error deleting package:", error);
    }
  };

  const handleEdit = (data) => {
    setFormData({
      title: data.title,
      content: data.content,
      benefits: data.benefits,
      price: data.price,
      type: data.type,
      isActive: data.isActive,
    });
    setPakId(data._id);
    setEdit(true);
    setAdd(true);
  };

  return (
    <div className="dark:bg-slate-900 md:mx-10 min-h-screen pb-4">
      {isDelete && (
        <DeletePopUp
          data={`Are you Confirm to delete ${pakName} subscription package!`}
          yes={yes}
          cancel={cancel}
        />
      )}
      {add ? (
        <div className="dark:bg-slate-900 pt-10 mb-10">
          <div className="flex flex-col pt-10 items-center min-h-screen w-full bg-white ">
            <div className="rounded-lg p-8 w-full sm:w-2/3 lg:w-1/2">
              <div className="pb-6 text-black mb-8 text-lg">
                {edit ? "Edit Membership" : "Add New Membership"}
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="font-normal mb-2 text-black inline-block">
                    Title
                  </label>
                  <select
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    className="bg-white autofill:bg-slate-800 relative block w-full px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm appearance-auto"
                    required
                  >
                    <option value="basic">Basic</option>
                    <option value="standard">Standard</option>
                    <option value="advance">Advance</option>
                    <option value="premium">Premium</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="font-normal mb-2 text-black inline-block">
                    Content
                  </label>
                  <input
                    type="text"
                    name="content"
                    value={formData.content}
                    onChange={handleInputChange}
                    className="bg-white autofill:bg-slate-800 relative block w-full appearance-none px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Content"
                    required
                  />
                </div>
                {/* <div className="mb-4">
                  <label className="font-normal mb-2 text-black inline-block">
                    Benefit
                  </label>
                  {formData.benefits.map((benefit, index) => (
                    <input
                      type="text"
                      key={index}
                      value={benefit}
                      onChange={(e) => handleBenefitChange(index, e.target.value)}
                      className="bg-white autofill:bg-slate-800 relative block w-full appearance-none px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 border-gray-200 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm rounded-md mb-2"
                      placeholder="Add Benefit"
                      required={index === 0}
                    />
                  ))}
                </div> */}
           <div className="mb-4">
          <label className="font-normal mb-2 text-black inline-block">
            Benefits
          </label>
          {formData.benefits.map((benefit, index) => (
            <div className="flex items-center gap-4" key={index}>
              <input
                type="text"
                value={benefit}
                onChange={(e) => handleBenefitChange(index, e.target.value)}
                className="bg-white autofill:bg-slate-800 relative block w-full appearance-none px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 border-gray-200 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm rounded-md mb-2"
                placeholder="Add Benefit"
                required={index === 0}
              />
              {index > 0 && (
                // <div
                //   className="text-lg cursor-pointer"
                 
                // >
            <svg  onClick={() => handleRemoveBenefit(index)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="black" class="w-5 mb-4 mr-2 cursor-pointer ">
  <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8.28 7.22a.75.75 0 0 0-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 1 0 1.06 1.06L10 11.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L11.06 10l1.72-1.72a.75.75 0 0 0-1.06-1.06L10 8.94 8.28 7.22Z" clip-rule="evenodd" />
</svg>

 
                // </div>
              )}
            </div>
          ))}
          <div
            className="text-[#E53E14]  justify-items-end text-xs cursor-pointer text-end"
            onClick={() => handleAddBenefit()}
          >
            + Add more benefits
          </div>
        </div>

                <div className="mb-4">
                  <label className="font-normal mb-2 text-black inline-block">
                    Price
                  </label>
                  <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleInputChange}
                    className="bg-white autofill:bg-slate-800 relative block w-full appearance-none px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Price"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="font-normal mb-2 text-black inline-block">
                    Type
                  </label>
                  <select
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                    className="bg-white autofill:bg-slate-800 relative block w-full px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm appearance-auto"
                    required
                  >
                    <option value="month">Month</option>
                    <option value="Year">Yearly</option>
                    <option value="HalfYear">Six Month</option>
                    <option value="Quater">Quarter</option>
                    <option value="ThreeMonth">Three Month</option>
                    <option value="TwoMonth">Two Month</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="font-normal mb-2 text-black inline-block">
                    Active
                  </label>
                  <select
                    name="isActive"
                    value={formData.isActive}
                    onChange={handleInputChange}
                    className="bg-white autofill:bg-slate-800 relative block w-full appearance-auto px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    required
                  >
                    <option value={false}>Inactive</option>
                    <option value={true}>Active</option>
                  </select>
                </div>
                <div className="my-6 default-btn-outer">
                  <button type="submit" className="default-btn">
                    {edit ? "Update Package" : "Add Package"}
                  </button>
                  <button
                    onClick={() => {
                      setAdd(false);
                      setEdit(false);
                      setFormData({
                         title: "basic",
                          content: "",
                           benefits: [""],
                          price: 0,
                          type: "month",
                           isActive: false,
                             });
                    }}
                    type="button"
                    className="default-btn cancel"
                  >
                    CANCEL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="pt-10">
          <div className="mb-2 md:mb-6 md:my-0">
            <div className="flex justify-between items-center border-b border-blue-200 border-opacity-20 h-14 mb-8">
              <div className="mb-1 text-4xl tracking-tight font-medium default-btn-outer ml-auto">
                <button
                  onClick={() => setAdd(true)}
                  type="button"
                  className="default-btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 pr-1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Add Membership
                </button>
              </div>
            </div>
          </div>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse bg-[#fff]" cellPadding={0} cellSpacing={0}>
            <thead className="text-xs text-gray-700 uppercase bg-[#FFF5F3] dark:text-gray-400">
              <tr className="text-slate-900 border-0">
                <th scope="col" className="px-6 py-3">
                  Title
                </th>
                <th scope="col" className="px-6 py-3">
                  Content
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Duration
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {allSub?.map((data) => (
                <tr
                  className="text-slate-900 border-0 border-solid border-b-2 border-gray-300 last:border-b-0"
                  key={data._id}
                >
                  <td className="px-6 py-4">{data?.title}</td>
                  <td className="px-6 py-4">{data?.content}</td>
                  <td className="px-6 py-4">{data?.price}</td>
                  <td className="px-6 py-4">{data?.type}</td>
                  <td className="px-6 py-4">
                    {data?.isActive === false ? "Inactive" : "Active"}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex justify-center">
                      <div
                        className="text-gray-600 mx-2 flex"
                        onClick={() => handleEdit(data)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          className="w-4 h-4 mx-2"
                        >
                          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                      </div>
                      <div
                        className="text-gray-600 mx-2 flex"
                        onClick={() => {
                          setPakId(data?._id);
                          setIsDelete(true);
                          setPakName(data?.title);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          fill="currentColor"
                          className="w-4 h-4 mx-2"
                        >
                          <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                        </svg>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MemberShipManaged;
