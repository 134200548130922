import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import DeletePopUp from "../components/deletePopUp";

const TrainingAmount = () => {
  const [allTit, setAllTit] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [titId, setTitId] = useState();
  const [titName, setTitName] = useState();
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    session: null,
    price: 0,
    isActive: true,
    description:null
  });

  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/allTraining`, {
        headers: {
          "x-access-token": localStorage.getItem("auth-token"),
        },
      });
      setAllTit(response?.data?.data);
    } catch (error) {
      console.error("Error fetching training plans:", error);
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = edit ? `${BASE_URL}/admin/updateTraining/${titId}` : `${BASE_URL}/admin/createTraining`;
      const response = await axios.post(url, formData, {
        headers: {
          "x-access-token": localStorage.getItem("auth-token"),
        },
      });
      if (response.data.success) {
        setFormData({
          title: "",
          session: null,
          price: 0,
          isActive: true,
          description:null
        });
        fetchPlans();
        setAdd(false);
        // setFormData({
        //   title: "",
        //   session: null,
        //   price: 0,
        //   isActive: true,
        // });
        setEdit(false);
      }
    } catch (error) {
      console.error("Error saving training package:", error);
    }
  };

  const yes = () => {
    handleDelete(titId);
  };

  const cancel = () => {
    setFormData({
      title: "",
      session: null,
      price: 0,
      isActive: true,
      description:null
    });
    setIsDelete(false);
    
  };

  const handleDelete = async (Id) => {
    try {
      await axios.post(`${BASE_URL}/admin/deleteTraining/${Id}`, {}, {
        headers: {
          "x-access-token": localStorage.getItem("auth-token"),
        },
      });
      fetchPlans();
      setIsDelete(false);
    } catch (error) {
      console.error("Error deleting package:", error);
    }
  };

  const handleEdit = (data) => {
    setFormData({
      title: data.title,
      session: data.session,
      price: data.price,
      isActive: data.isActive,
      description:data.description
    });
    setTitId(data._id);
    setEdit(true);
    setAdd(true);
  };

  return (
    <div className="dark:bg-slate-900 md:mx-10 min-h-screen pb-4">
      {isDelete && <DeletePopUp data={`Are you sure you want to delete ${titName} training package?`} yes={yes} cancel={cancel} />}
      {add ? (
        <div className="dark:bg-slate-900 pt-10">
          <div className="flex flex-col pt-10 items-center min-h-screen bg-white rounded-lg">
            <div className="p-8 w-full sm:w-2/3 lg:w-1/2">
              <div className="pb-6 text-black mb-8 text-lg">{edit ? "Edit Training Package" : "Add New Training Package"}</div>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="font-normal mb-2 text-black inline-block">
                    Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={formData.title}
                    onChange={handleInputChange}
                    className="bg-white autofill:bg-slate-800 relative block w-full appearance-none px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Title"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="font-normal mb-2 text-black inline-block">
                    Session
                  </label>
                  <input
                    type="number"
                    name="session"
                    value={formData.session}
                    onChange={handleInputChange}
                    className="bg-white autofill:bg-slate-800 relative block w-full appearance-none px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Session number"
                    required
                  />
                </div>
            
                <div className="mb-4">
                  <label className="font-normal mb-2 text-black inline-block">
                    Price
                  </label>
                  <input
                    type="number"
                    name="price"
                    value={formData.price}
                    onChange={handleInputChange}
                    className="bg-white autofill:bg-slate-800 relative block w-full appearance-none px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Price"
                    required
                  />
                </div>
            
                <div className="mb-4">
                  <label className="font-normal mb-2 text-black inline-block">
                    Description
                  </label>
                  <input
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="bg-white autofill:bg-slate-800 relative block w-full appearance-none px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    placeholder="Description"
                    required
                  />
                </div>
        
                <div className="mb-4">
                  <label className="font-normal mb-2 text-black inline-block">
                    Active
                  </label>
                  <select
                    name="isActive"
                    value={formData.isActive}
                    onChange={handleInputChange}
                    className="bg-white autofill:bg-slate-800 relative block w-full appearance-auto px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    required
                  >
                    <option value={false}>Inactive</option>
                    <option value={true}>Active</option>
                  </select>
                </div>
                <div className="my-6 default-btn-outer">
                  <button type="submit" className="default-btn">
                    {edit ? "Update Training" : "Add Training"}
                  </button>
                  <button onClick={() => { setAdd(false); setEdit(false); setFormData({  title: '',  session: null,  price: 0,  isActive: true, description:null}); }} 
                  type="button" className="default-btn cancel">
                    CANCEL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <div className="pt-10">
          <div className="mb-2 md:mb-6 md:my-0">
            <div className="flex justify-between items-center border-b border-blue-200 border-opacity-20 h-14 mb-8">
              <div className="mb-1 text-4xl tracking-tight font-medium default-btn-outer ml-auto">
                <button
                  onClick={() => setAdd(true)}
                  type="button"
                  className="default-btn"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 pr-1"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Add Training Package
                </button>
              </div>
            </div>
          </div>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-900 border-collapse bg-[#fff]" cellPadding={0} cellSpacing={0}>
            <thead className="text-xs text-gray-700 uppercase bg-[#FFF5F3] dark:text-gray-900">
              <tr className="text-slate-900 border-0">
                <th scope="col" className="px-6 py-3">
                  Title
                </th>
                <th scope="col" className="px-6 py-3">
                Session
                </th>
               
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
               
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {allTit?.map((data) => (
                <tr className="text-slate-900 border-0 border-solid border-b-2 border-gray-300 last:border-b-0" key={data._id}>
                  <td className="px-6 py-4">{data?.title}</td>
                  <td className="px-6 py-4">{data?.session}</td>
                  <td className="px-6 py-4">{data?.price}</td>
                  <td className="px-6 py-4">{data?.isActive === false ? "Inactive" : "Active"}</td>
                  <td className="px-6 py-4">
                    <div className="flex justify-center">
                      <div className="text-gray-900 mx-2 flex" onClick={() => handleEdit(data)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mx-2">
                          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                      </div>
                      <div className="text-gray-900 mx-2 flex" onClick={() => { setTitId(data._id); setIsDelete(true); setTitName(data.title); }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor" className="w-4 h-4 mx-2">
                          <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                        </svg>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TrainingAmount;
