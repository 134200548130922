import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { BASE_URL } from "../../config";
import DateSlotPicker from "../../components/dateSlotPicker";

const AddUnavailableSlots = ({back}) => {
    const navigate = useNavigate();
    const [bookedSlots, setBookedSlots] = useState([]);
    const [unavailableSlots, setUnavailableSlots] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
        let isAdmin = ['admin'].includes(localStorage.getItem('roles'));
            
        // If roles is not admin, redirect to dashboard page
        if (!isAdmin) {
            window.location = window.location.origin + '/membership';
            return false;
        }
    });

    const addUnavailableSlots = () => {
        let bookedSlotsData = bookedSlots.map((data) => ({
            date: data?.date,
            startTime: data?.time?.startTime,
            endTime: data?.time?.endTime,
        }));

        axios({
            headers: {
                "x-access-token": localStorage.getItem('auth-token')
            },
            url: `${BASE_URL}/admin/slots/unavailable`, //your url
            method: 'post',
            data: bookedSlotsData
        }).then((res) => {
            navigate('/training-slots');
            setLoading(false);
            back(9)
        });
    };
const backFun=(val)=>{
    back(val)
}
    return (
        <div className="min-h-screen md:mx-10 md:h-auto mt-5">
            <div className="p-4 md:p-6 bg-white rounded-lg">
                <div className="px-10 pt-4 mb-3 text-xl tracking-tight font-medium text-black dark:text-black">Add Available Slots</div>
                    <DateSlotPicker bookedSlots={bookedSlots} setBookedSlots={setBookedSlots} backFun={backFun} slotSelectionLabel="Please select available slots" selectedSlotLabel="Available Slots" buttonLabel="Add Slot" />
                
                {/*
                <div className="flex justify-center">
                    <button onClick={() => addUnavailableSlots()} id="submit" type="submit" className={`mx-2 my-8 inline-flex w-[200px] items-center justify-center rounded bg-theme-blue-secondary py-2 px-4 text-gray-400 hover:text-opacity-100`}>
                        Add Slots
                    </button>

                    <button onClick={() => navigate('/training-slots')} type="button" className={`mx-2 my-8 inline-flex w-[200px] items-center justify-center rounded bg-theme-blue-secondary py-2 px-4 text-gray-400 hover:text-opacity-100`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path fill="#A8AAAD" d="M10 17l-5-5 5-5v10z"/>
                            <path fill="none" d="M0 0h24v24H0z"/>
                        </svg>
                        Back
                    </button>
                </div>
                */}
            </div>
        </div>
    );
}

export default AddUnavailableSlots;