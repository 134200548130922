import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL, FITBIT_CLIENT_ID } from "../config";
import Modal from "../components/modal";
import GarminModal from "../components/garminModal";
import Calendar from "../components/calendar";
import Tooltip from "../components/tooltip";
import { useNavigate } from "react-router-dom";
import SamsungUrl from "../assets/samsung_logo.png";
import bluetoothConnect from "../assets/images/bluetoothConnect.svg"
import bluetoothDisabled from "../assets/images/bluetoothDisabled.svg"
import fitbitIcon from "../assets/images/fitbitIcon.svg"
import garminIcon from "../assets/images/garminIcon.svg"

const Devices = () => {
  const navigate = useNavigate();

  useEffect(() => {
  let subscribedStatus = localStorage.getItem('subscribedStatus');
      
  // If subscribedStatus is falsy, redirect to membership page
  if (subscribedStatus == 'false') {
      window.location = window.location.origin + '/membership';
      return false;
  }
})

  const [fitbitChecked, setFitbitChecked] = useState(false);
  const [garminChecked, setGarminChecked] = useState(false);
  const [stravaChecked, setStravaChecked] = useState(false);
  const [samsungChecked, setSamsungChecked] = useState(false);
  const [fitbitConnectLoading, setFitbitConnectLoading] = useState(false);
  const [deviceStatusLoading, setDeviceStatusLoading] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deviceType, setDeviceType] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState({});
 const [gartokcheck,setGartokcheck]=useState(false);
  const onClickFitbit = () => {
    if (fitbitConnectLoading) {
      return;
    }
    if (fitbitChecked) {
      modelDeviceType('FitBit');
      setConfirmationModal(true);
    } else {
      //setFitbitChecked(true);
      setFitbitConnectLoading(true);
      window.location.href = `https://www.fitbit.com/oauth2/authorize?client_id=${FITBIT_CLIENT_ID}&response_type=code&scope=activity%20heartrate%20location%20nutrition%20oxygen_saturation%20profile%20respiratory_rate%20settings%20sleep%20social%20temperature%20weight%20cardio_fitness%20electrocardiogram%20heartrate`;
    }
  };

  const onClickGarmin = () => {
    if(garminChecked){
      modelDeviceType('Garmin');
      setConfirmationModal(true);
    }else{
    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
      },
      url: `${BASE_URL}/garmin/getAuthorizationLink`, //your url
      method: "POST",
    }).then((res) => {
      window.location.href = res?.data?.redirectUrl;
      // setConfirmationModal(false);
      // getDeviceStatus();
    });}
  };

  const onModalConfirm = () => {
    setFitbitChecked(false);

    if (deviceType == 'Garmin') {
      removeGarminUSer();
    }
    if (deviceType == 'FitBit') {
      removeFitBitToken();
    }


  };

  const removeFitBitToken = () => {
    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
      },
      url: `${BASE_URL}/auth/removeFitBitToken`, //your url
      method: "PUT",
    }).then((res) => {
      setConfirmationModal(false);
      getDeviceStatus();
    });
  };
  const removeGarminUSer = () => {
    console.log("remove garmi model function removed ")
    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
      },
      url: `${BASE_URL}/auth/removegarmin`, //your url
      method: "PUT",
    }).then((res) => {
      setConfirmationModal(false);
      getDeviceStatus();
    });
  };
  const getDeviceStatus = () => {
    setDeviceStatusLoading(true);
    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
      },
      url: `${BASE_URL}/user/device/status`, //your url
      method: "GET",
    }).then((res) => {
      setDeviceStatusLoading(false);
      setFitbitChecked(res.data.isFitbitConnected);
      setGarminChecked(res.data.isGarminConnected);
      setGartokcheck(res.data.isGarminConnectedthroughtoken);
    }).catch((err) => {
      if(err.code == "ERR_BAD_REQUEST"){ // Unauthorized 401
        //console.log("Error : ", err.code);
        localStorage.clear(); // Remove token from local storage
        navigate('/'); // Redirect to the login page
      }
    });
  };

  const getProfile = (event) => {
    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
      },
      url: `${BASE_URL}/user/profile`, //your url
      method: 'GET'
    }).then((res) => {
      setUser(res?.data?.data);
    }).catch((err) => {
      if(err.code == "ERR_BAD_REQUEST"){ // Unauthorized 401
        //console.log("Error : ", err.code);
        localStorage.clear(); // Remove token from local storage
        navigate('/'); // Redirect to the login page
      }
    });
  };

  useEffect(() => {
    getDeviceStatus();
    getProfile();
  }, []);

  const updateWalkthrough = (e) => {
    axios({
      headers: {
        "x-access-token": localStorage.getItem("auth-token"),
      },
      url: `${BASE_URL}/user/update/walkthrough`, //your url
      method: 'PUT',
      data: { walkThroughPending: false, walkThroughSteps: 2 }
    }).then((res) => {
      localStorage.setItem('walkthrough', JSON.stringify({ walkThroughPending: false, walkThroughSteps: 2 }))
    }).catch((err) => {
    });
  };

  function modelDeviceType(deviceType) {
    setDeviceType(deviceType);
  };

  function closeModal() {
    setIsOpen(false);
    setGarminChecked(garminChecked);
  };

  const onClickViewDashboard = () => {
    if (garminChecked || fitbitChecked) {
      navigate('/dashboard');
      updateWalkthrough();
    }
  };

  const walkThroughPending = JSON.parse(localStorage.getItem('walkthrough'))?.walkThroughPending;

  return (
    <div
      className="dark:bg-slate-900"
      style={{ height: "100vh" }}
    >
      <Modal
        title={"Disconnect Device"}
        content={"Do you really want to disconnect device ?"}
        modalShow={confirmationModal}
        onModalConfirm={onModalConfirm}
        setModalShow={setConfirmationModal}
        deviceType={deviceType}
      />
      <GarminModal
        title={"Gamin Authentication"}
        content={"Please enter your garmin Login details!"}
        modalShow={modalIsOpen}
        onModalConfirm={closeModal}
        setModalShow={setIsOpen}
        setGarminChecked={setGarminChecked}
      />
      <div className="w-full p-5 ">
        <div className="px-2 devices-col-section">
          {/* <div className="flex align-center items-center justify-between border-b border-blue-200 border-opacity-20 h-14 mb-8">
            <div className="text-2xl tracking-tight font-medium text-gray-300 dark:text-white">
              Devices
            </div>
          </div> */}
          <ul role="list" className="px-2 py-2 flex list-none gap-5">
            <li
              className={`p-3 bg-white w-3/12 text-center col-box ${(fitbitConnectLoading || deviceStatusLoading) && "opacity-40"
                } p-2 rounded my-2`}
            >
              <div className="">
                <div className="icon-box">
                  <img
                   src={fitbitIcon}
                   alt="Neil image"
                  />
                </div>
                <div className="">
                  <p className="font-medium text-black truncate dark:text-white mt-3">
                    FITBIT
                  </p>
                  {(fitbitConnectLoading || deviceStatusLoading) ? (
                    <div className="lds-facebook">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    <p className="text-gray-500 text-xs truncate dark:text-gray-400 bg-[36353880] mb-4">
                      {fitbitChecked ? "connected" : "disconnected"}
                    </p>
                  )}
                </div>
                <div className="items-center text-base font-medium text-gray-600 dark:text-white">
                  <label className="relative items-center cursor-pointer">
                    <input
                      onClick={onClickFitbit}
                      type="checkbox"
                      value=""
                      className="sr-only peer"
                      checked={fitbitChecked}
                    />
                    {/* <div className="w-11 h-6 bg-gray-300 peer-checked:bg-[#415E96] rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-gray-700 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-gray-100 after:border-gray-400 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"></div> */}
                    <div className={fitbitChecked ? "connect-box  disconect peer-checked:bg-[#e53e144d]" : "connect-box connect peer-checked:bg-[#e53e144d]"}>
              
                  <img
                    className="connect-icon"
                    src={bluetoothConnect}
                    alt="Icon"
                  />
                  <img
                    className="disconnect-icon"
                    src={bluetoothDisabled}
                    alt="Icon"
                  />
                {fitbitChecked ? "Disconnect" : "Connect"}
                </div>

                    <span className="text-sm font-medium text-gray-900 dark:text-gray-400"></span>
                  </label>
                </div>

              </div>
            </li>
            <li className={`p-3 bg-white w-3/12 text-center col-box ${(deviceStatusLoading) && "opacity-40"} rounded my-2`}>
              <div className="">
                <div className="icon-box">
                  <img
                    src={garminIcon}
                    alt="Bonnie image"
                  />
                </div>
                <div className="">
                  <p className="font-medium text-black truncate dark:text-white mt-3">
                    <div className="">
                      GARMIN
                      {(!user?.weight || !user?.age || !user?.gender || !user?.height) && (<Tooltip text={<span>Update your details on <span onClick={() => navigate('/profile')} className="cursor-pointer font-medium text-blue-400 dark:text-blue-500 underline" href='www.google.com'>profile</span></span>}>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="yellow" class="cursor-pointer w-6 h-6 ml-4">
                          <path stroke-linecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                        </svg> */}
                      </Tooltip>
                      )}
                    </div>
                  </p>
                  {deviceStatusLoading ? (
                    <div className="lds-facebook">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    <p className="text-gray-500 text-xs truncate dark:text-gray-400 bg-[36353880] mb-4">
                      {garminChecked ? "connected" : "disconnected"}
                    </p>
                  )}
                </div>
                <div className="items-center text-base font-medium text-gray-600 dark:text-white">
                  <label className="relative items-center cursor-pointer">
                    <input
                      onClick={onClickGarmin}
                      type="checkbox"
                      value=""
                      className="sr-only peer"
                      checked={garminChecked}
                      // disabled={garminChecked}
                    />
                    {/* <input onClick={() => setGarminChecked(!garminChecked)} type="checkbox" value="" className="sr-only peer" checked={garminChecked} /> */}
                    {/* <div className="w-11 h-6 bg-gray-300 peer-checked:bg-[#415E96] rounded-full dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-gray-700 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-gray-100 after:border-gray-400 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"></div> */}
                    {/* <div className="connect-box connect peer-checked:bg-[#e53e144d]"> */}
                    <div className={garminChecked && gartokcheck ? "connect-box  disconect peer-checked:bg-[#e53e144d]" : "connect-box connect peer-checked:bg-[#e53e144d]"}>
                  <img
                    className="connect-icon"
                    src={bluetoothConnect}
                    alt="Icon"
                  />
                  <img
                    className="disconnect-icon"
                    src={bluetoothDisabled}
                    alt="Icon"
                  />
                 {garminChecked ? "Disconnect" : "Connect"}
                </div>
                    <span className="text-sm font-medium text-gray-900 dark:text-gray-400"></span>
                  </label>
                </div>





              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

  );
};
export default Devices;
