import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "../../config";
import Table from '../../components/table';
import Modal from '../../components/modal';
import DragAndDrop from '../../components/dragAndDrop';
import axios from 'axios';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CompEmailEdit from "./compEmailEdit";
import CompanyEmployee from './companyEmployee';
const WellnessProgram = ({ back }) => {
    const navigate = useNavigate();
    const [rowHeaderData, setRowHeaderData] = useState([]);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [deleteId, setDeleteId] = useState('');
    const [bulkUploadModal, setBulkUploadModal] = useState(false);
    const [inviteBulkLoading, setInviteBulkLoading] = useState(false);
    const [error, setError] = useState('');
    const [isChecked, setIsChecked] = useState(true); // Toggle state
    const [datafor, setDatafor] = useState("invite");
    const [manedit,setManedit]=useState(false);
    const [companyEmp,setCompanyEmp]=useState(false);
    const[compId,setCompId]=useState();
    useEffect(() => {
        let isAdmin = ['admin'].includes(localStorage.getItem('roles'));

        // If roles is not admin, redirect to dashboard page
        if (!isAdmin) {
            window.location = window.location.origin + '/membership';
            return false;
        }
    }, []);

    const getCompanyDetail = async () => {
        setLoading(true);
        var response;
        if (datafor === "invite") {
            response = await axios.get(`${BASE_URL}/user/invite/company/list`, {
                headers: {
                    "x-access-token": localStorage.getItem("auth-token"),
                },
            });
        } else if (datafor === "comp") {
            response = await axios.get(`${BASE_URL}/user/company`, {
                headers: {
                    "x-access-token": localStorage.getItem("auth-token"),
                },
            });
        }
        if (response?.data?.sucess === true) {
            setUserData(response?.data?.data);
        }
        setLoading(false);
    };

    const handleToggle = () => {
        setIsChecked(!isChecked);
        setDatafor(isChecked ? "comp" : "invite");
    };

 

    const getUserDetail = async () => {
        setLoading(true);
        const response = await axios.get(`${BASE_URL}/user/invite/user/list`, {
            headers: {
                "x-access-token": localStorage.getItem('auth-token')
            },
        });
        setUserData(response?.data?.data);
        setLoading(false);
    };

    const onClickDeleteUser = async (id) => {
        await axios.delete(`${BASE_URL}/user/${id}`, {
            headers: {
                "x-access-token": localStorage.getItem('auth-token')
            },
        });
        setRowHeaderData(userTableRowTitle);
        getUserDetail();
    };

    const onClickDeleteCompany = async (id) => {
        await axios.delete(`${BASE_URL}/user/company/${id}`, {
            headers: {
                "x-access-token": localStorage.getItem('auth-token')
            },
        });
        setRowHeaderData(companyTableRowTitle);
        getCompanyDetail();
    };

    const inviteBulkUser = async (bulkUserForm) => {
        setInviteBulkLoading(true);
        try {
            await axios.post(`${BASE_URL}/user/invite/user/bulk`, bulkUserForm, {
                headers: {
                    "x-access-token": localStorage.getItem('auth-token')
                },
            });
            setBulkUploadModal(false);
            getUserDetail();
        } catch (err) {
            if (err?.response?.data?.message) {
                setError(err?.response?.data?.message);
                setTimeout(() => {
                    setError('');
                }, 3000);
            }
        }
        setInviteBulkLoading(false);
    };

    const userTableRowTitle = [
        { title: "Company Name", key: "companyName" },
        { title: "Company Type", key: "typeOfCompany" },
        { title: "Email", key: "email" },
        { title: "Person In Charge", key: "personInCharge" },
    ];

    const companyTableRowTitle = [
        { title: "Company Name", key: "companyName" },
        { title: "Company Type", key: "typeOfCompany" },
        { title: "Email", key: "email" },
        { title: "Person In Charge", key: "personInCharge" },
        { title: "Registration Status", key: "isActive", formatData: (data) => data ? "pending" : "success" },
    ];
    const goBack = () => {
        setManedit(false);
        // back(11)
        getCompanyDetail();
      };

      useEffect(() => {
        getCompanyDetail();
    }, [datafor]);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        if (localStorage.getItem('roles') === "company") {
            setRowHeaderData(userTableRowTitle);
            getUserDetail();
        } else {
            setRowHeaderData(companyTableRowTitle);
            getCompanyDetail();
        }
    }, []);

    useEffect(() => {
        if (isChecked) {
            getCompanyDetail();
            setRowHeaderData(companyTableRowTitle);
        } else {
            getCompanyDetail();
            setRowHeaderData(companyTableRowTitle);
        }
    }, [isChecked,datafor]);
    
    const getActionData = (data) => (
        <span className="flex">
            {(localStorage.getItem('roles') === "admin") &&
                <svg onClick={() => { navigate(`/wellnessprogram/company/${data.email}`); localStorage.setItem('selectedCompany', JSON.stringify(data)); back(17) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="cursor-pointer w-5 h-5 mr-4">
                    <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                    <path fillRule="evenodd" d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clipRule="evenodd" />
                </svg>
            }
            {/* <svg onClick={() => navigator.clipboard.writeText(`https://app.contingentfitness.com/sign-up/${(localStorage.getItem('roles') === "admin") ? 'company' : 'user'}/${data?.token}`)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="cursor-pointer w-5 h-5 mr-4">
                <path d="M7 3.5A1.5 1.5 0 018.5 2h3.879a1.5 1.5 0 011.06.44l3.122 3.12A1.5 1.5 0 0117 6.622V12.5a1.5 1.5 0 01-1.5 1.5h-1v-3.379a3 3 0 00-.879-2.121L10.5 5.379A3 3 0 008.379 4.5H7v-1z" />
                <path d="M4.5 6A1.5 1.5 0 003 7.5v9A1.5 1.5 0 004.5 18h7a1.5 1.5 0 001.5-1.5v-5.879a1.5 1.5 0 00-.44-1.06L9.44 6.439A1.5 1.5 0 008.378 6H4.5z" />
            </svg> onClick={()=>{setManedit(data?._id)}} */}
        { data?.email==null && 
       <svg
       onClick={()=>{setManedit(data?._id)}} 
       xmlns="http://www.w3.org/2000/svg"
       className="h-5 w-5 mr-2"
       viewBox="0 0 20 20"
       fill="currentColor"
     >
       <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
     </svg>
}


            {((localStorage.getItem('roles') === "admin") || (localStorage.getItem('roles') === "company")) &&
                ((deleteId === data?._id) ? (
                    <div className="flex">
                        <svg onClick={() => (localStorage.getItem('roles') === "company") ? onClickDeleteUser(data?._id) : onClickDeleteCompany(data?._id)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 cursor-pointer">
                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                        </svg>
                        <svg onClick={() => setDeleteId('')} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 cursor-pointer">
                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
                        </svg>
                    </div>
                ) : (
                    <svg onClick={() => setDeleteId(data?._id)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 mr-4 cursor-pointer">
                        <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z" clipRule="evenodd" />
                    </svg>
                ))
            }
        </span>
    );

    return (
        <>
            <div className="h-screen mx-0 pt-10">
                {/* {manedit} */}
                {manedit && <CompEmailEdit   _id={manedit} goBack={goBack} /> }
                <div className="flex justify-between items-center border-b border-blue-200 border-opacity-20 h-14 mb-8 mx-10">
                    <div className="text-2xl tracking-tight font-medium text-black dark:text-white">
                        {(localStorage.getItem('roles') === "company") ? 'User Invite' : 'Company '}
                    </div>
                    <div className="text-4xl tracking-tight font-medium text-gray-300 dark:text-white">
                        {(localStorage.getItem('roles') === "company") && (
                            <button onClick={() => setBulkUploadModal(true)} type="button" className="button-theme font-medium rounded-full text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55 mr-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 mr-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15" />
                                </svg>
                                Bulk Invite
                            </button>
                        )}
                        <button onClick={() => { navigate(`/wellnessprogram/invite-${(localStorage.getItem('roles') === "company") ? 'user' : 'company'}`); back(16) }} type="button" className="bg-[#E53E14] font-medium text-white border-0 rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#4285F4]/55">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 pr-1">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z" clipRule="evenodd" />
                            </svg>
                            {(localStorage.getItem('roles') === "company") ? 'Invite User' : 'Invite Company'}
                        </button>
                    </div>
                </div>
                <div className="mb-2 pl-12 md:mb-6 md:my-0 flex justify-between items-center" style={{display: "none"}}>
                    <label className="inline-flex items-center cursor-pointer ">
                        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 mr-3">Company</span>
                        <input
                            type="checkbox"
                            className="sr-only peer mx-2"
                            checked={isChecked}
                            onChange={handleToggle}
                        />
                        <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 peer-checked:after:right-0"></div>
                        <span className="ms-3 ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Invited Company</span>
                    </label>
                </div>
                <div className="mx-2 md:my-0 md:mx-10">
                </div>
                {loading ? (
                    <div className="mb-6 mx-2 md:my-6 md:mx-10 rounded">
                        <Skeleton
                            className="mb-4"
                            baseColor="#ccc"
                            highlightColor="#fff"
                            height={140}
                            count={4}
                        />
                    </div>
                ) : (
                    <Table key={datafor} loading={loading} headerData={rowHeaderData} rowData={userData} getActionData={getActionData} />
                )}
                <Modal
                    title={"Bulk User Upload"}
                    content={(
                        <DragAndDrop modalShow={bulkUploadModal} uploadLoading={inviteBulkLoading} inviteBulkUser={inviteBulkUser} />
                    )}
                    modalShow={bulkUploadModal}
                    inviteBulkUser={(data) => inviteBulkUser(data)}
                    setModalShow={setBulkUploadModal}
                    notShowFooter={true}
                />
            </div >
        </>
    );
}

export default WellnessProgram;
