import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Calendar from '../components/calendar';
import { useNavigate } from "react-router-dom";
import { BASE_URL, FITBIT_CLIENT_ID } from "../config";
const Dashboard = () => {
    const navigate = useNavigate();
    const [garConnect, setGarConnect] = useState(false);
    const [fitConnect, setFitConnect] = useState(false);
    const [gartokConnect, setGartokConnect] = useState(false);
    const [walkThroughPending, setWalkThroughPending] = useState(JSON.parse(localStorage.getItem("walkthough")).walkThroughPending);
    const [walkThroughSteps, setWalkThroughSteps] = useState(JSON.parse(localStorage.getItem("walkthough")).walkThroughSteps);

    useEffect(() => {
        let userStatus = localStorage.getItem('status');
        let subscribedStatus = localStorage.getItem('subscribedStatus');
            if (subscribedStatus == 'false' ) {
            window.location = window.location.origin + '/membership';
            return false;
        } else if (fitConnect=='false' && garConnect =='false' ) {
            navigate('/device');
        } 
        // else if (walkThroughPending =='true' || walkThroughSteps !== 2) {
        //     navigate('/device'); 
        // }
    }, []);

    useEffect(() => {
        const getDeviceStatus = async () => {
            try {
                const res = await axios({
                    headers: {
                        "x-access-token": localStorage.getItem("auth-token"),
                    },
                    url: `${BASE_URL}/user/device/status`,
                    method: "GET",
                });
                setGarConnect(res.data.isGarminConnected);
                setFitConnect(res.data.isFitbitConnected);
                setGartokConnect(res.data.isGarminConnectedthroughtoken);
            } catch (err) {
                if (err.code === "ERR_BAD_REQUEST") {
                    // Handle error
                }
            }
        };

        getDeviceStatus();
    }, []);

    useEffect(() => {
        if (!fitConnect && !garConnect ) {
            navigate('/device');
        } else if (walkThroughPending || walkThroughSteps !== 2) {
            navigate('/device'); 
        }
    }, [fitConnect, garConnect, gartokConnect, walkThroughPending, walkThroughSteps, navigate]);

    const events = [{
        id: 1,
        color: '#FFFFFF',
        from: '2023-02-01',
        to: '2023-02-01',
        title: `⏱️2330 🔥2152`
    },
    {
        id: 2,
        color: '#FFFFFF',
        from: '2023-02-02',
        to: '2023-02-02',
        title: `⏱️2330 🔥2152`
    },
    {
        id: 1,
        color: '#FFFFFF',
        from: '2023-02-03',
        to: '2023-02-03',
        title: `⏱️2330 🔥2152`
    },
    {
        id: 2,
        color: '#FFFFFF',
        from: '2023-02-04',
        to: '2023-02-04',
        title: `⏱️2330 🔥2152`
    },
    {
        id: 1,
        color: '#FFFFFF',
        from: '2023-02-05',
        to: '2023-02-05',
        title: `⏱️2330 🔥2152`
    }, {
        id: 2,
        color: '#FFFFFF',
        from: '2023-02-06',
        to: '2023-02-06',
        title: `⏱️2330 🔥2152`
    }];

    return (
        <div className="dark:bg-slate-900 min-h-[88vh]">
            <div className="calendar-background">
                <Calendar events={events} />
            </div>
        </div>
    );
}

export default Dashboard;
