import React from "react";
const NotifyPopUp = ({ data,ok }) => {
  return (
    <>
      <div
        id="defaultModal"
        tabindex="-1"
        aria-hidden="true"
        class="fixed flex justify-center bg-gray-900 bg-opacity-90 pt-[100px] z-50 w-full p-4 overflow-x-hidden false overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div class="relative w-full max-w-3xl max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 p-2">
            <div class="flex items-start justify-between p-4 b rounded-t dark:border-gray-600">
              <div class="text-xl text-black dark:text-white font-medium">
                Alert !
              </div>
            </div>
            <div class="px-5 space-y-6 flex">
              <p class="text-base leading-relaxed text-black dark:text-gray-400">
                {data}
              </p>
            </div>
            <div class="flex justify-center p-6 space-x-2 rounded-lg dark:border-gray-600">
              <button
                data-modal-hide="defaultModal"
                type="button"
                onClick={ok}
                class="mx-4 font-medium rounded-lg text-sm px-10 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 bg-[#186CED] text-white border-[#186CED]"
              >
                YES
              </button>
           
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NotifyPopUp;
