
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as XLSX from 'xlsx';
import adminDumny from "../../assets/images/adminDumny.svg";
import DeletePopUp from '../../components/deletePopUp';
import CompUserEdit from './compnyUserEdit';
import { BASE_URL } from "../../config";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AllCompUser = ({ back }) => {
    const navigate = useNavigate();
    const [ema, setEma] = useState(true);
    const [add, setAdd] = useState();
    const [msg, setMsg] = useState();
    const [companyId, setCompanyId] = useState();
    const [datafor, setDatafor] = useState("active");
    const [data, setData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [profileImages, setProfileImages] = useState({});
    const [duplicate, setDuplicate] = useState();
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [delUser, setDelUser] = useState();
    const [nameForPopup, setNameForPopup] = useState();
    const [loading, setLoading] = useState(false);
    const [showUserDetail, setShowUserDetail] = useState("");
const [compemail,setCompemail]=useState();
    const [formData, setFormData] = useState({
        email: "",
        firstName: "", lastName: " "
    });
    const [file, setFile] = useState(null);

    useEffect(() => {
        let isAdmin = ['company', 'admin'].includes(localStorage.getItem('roles'));
        if (!isAdmin) {
            window.location = window.location.origin + '/membership';
            return false;
        }
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "email") {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailPattern.test(value)) {
                setMsg(" * Invalid email ");
                setEma(true);
                return;
            } else {
                setMsg();
                setEma(false);
            }
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        let n = JSON.parse(localStorage.getItem("company"));
        setCompanyId(n?._id);
        getallActiveUser(n?._id);
    }, []);

    const handleSubmit = async (e) => {
        
        try {
            const updateddata = { ...formData, compId: companyId };

            setLoading(true);

            axios({
                headers: {
                    "x-access-token": localStorage.getItem('auth-token')
                },
                url: `${BASE_URL}/user/invite/user`, //your url
                method: 'post',
                data: updateddata
            }).then((res, err) => {
                navigate('/Company/User');
                setLoading(false);
                back(25);
                window.location.reload(); 
            }).catch(err => {
                if (err?.response?.data?.message) {
                    setMsg(err?.response?.data?.message);
                    setTimeout(() => {
                        setMsg('');
                    }, 5000);

                    setLoading(false);

                    return false;
                }
            });

            navigate('/Company/User');
            setLoading(false);
            back(25);

        } catch (err) {
            console.log("Request not sent.");
            setMsg("Request not sent.");

            return false;
        }
    }

    const getallActiveUser = async (id) => {
        try {
            if (id) {
                var response;
                if (datafor === "Inactive") {
                    response = await axios.get(`${BASE_URL}/user/list/inviteUser/${id}`, {
                        headers: {
                            "x-access-token": localStorage.getItem("auth-token"),
                        },
                    });
                } else {
                    response = await axios.get(`${BASE_URL}/user/list/user/${id}`, {
                        headers: {
                            "x-access-token": localStorage.getItem("auth-token"),
                        },
                    });
                }
                if (response?.data?.sucess == true) {
                    setData(response?.data?.data);

                }
            }
        } catch (error) {
            setMsg("no data with this user");
        }
    }

    const fetchProfileImage = async (id) => {
    await    axios({
            headers: {
                "x-access-token": localStorage.getItem("auth-token"),
            },
            url: `${BASE_URL}/user/profile/image/${id}`,
            method: "GET",
            responseType: "blob",
        })
            .then((res) => {
                const url = URL.createObjectURL(res.data);
                setProfileImages((prevImages) => ({ ...prevImages, [id]: url }));
            })
            .catch((err) => {
                if (err?.response?.data?.message) {
                    console.log("there is no image with this user");
                }
            });
    };

    const handleToggle = () => {
        setIsChecked(!isChecked);
        setDatafor(isChecked ? "active" : "Inactive");
    };

    useEffect(() => {
        let n = JSON.parse(localStorage.getItem("company"));
        setCompanyId(n?._id);
        getallActiveUser(n?._id);

        data.forEach((user) => {
            fetchProfileImage(user?._id);
        });
    }, [datafor, deletePopUp,showUserDetail]);
useEffect(()=>{
    data.forEach((user) => {
        // console.log(user?._id ,"----")
        fetchProfileImage(user?._id);
    });
    setCompemail(localStorage.getItem("email"))
},[data])
    const handleFileChange = (e) => {
        console.log("Selected file:", e.target.files[0]);
        setFile(e.target.files[0]);
    };

    const handleFileUpload = () => {
        if (!file) {
            console.error("No file selected!");
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(sheet);

            axios.post(`${BASE_URL}/user/invite/user/bulk`, { json, compId: companyId }, {
                headers: {
                    "x-access-token": localStorage.getItem("auth-token"),
                },
            })
                .then(response => {
                    alert('Bulk invitations sent successfully!');
                    console.log(response?.data?.alredyAccount);
                    console.log(response?.data?.alredyAccount.length);
                    if (response?.data?.alredyAccount.length === 0) {

                        setAdd(false);
                    } else {
                        setAdd("Dupli");
                        setDuplicate(response?.data?.alredyAccount);
                    }
                })
                .catch(error => {
                    console.error('Error sending bulk invitations:', error);
                });
        };
        reader.onerror = (error) => {
            console.error("Error reading file:", error);
        };
        reader.readAsArrayBuffer(file);
    };

    const yes = () => {
        handleDelete(delUser);
    };

    const cancel = () => {
        setDeletePopUp(false);
    };

    const handleDelete = async (delUser) => {
        try {
            await axios({
                headers: {
                    "x-access-token": localStorage.getItem("auth-token"),
                },
                url: `${BASE_URL}/user/deleteByCom/${delUser}/${datafor === "Inactive" ? 2 : 1}`,
                method: "delete",
            })
                .then((res) => {
                    if (res.data.success) {
                        setDeletePopUp(false);
                    } else {
                        setDelUser();
                        console.log(res.data.message);
                    }
                })
                .catch(function (error) {
                    console.log(error.message);
                });
        } catch (error) {
            console.error("Error deleting coupon:", error);
        }
    };

    return (
        <div className="dark:bg-slate-900 md:mx-10 min-h-screen pb-4">
            {deletePopUp && <DeletePopUp
                data={`Are you sure you want to delete this user ${nameForPopup} from Contingent Fitness?`}
                yes={yes}
                cancel={cancel}
            />}
            {add === "Dupli" && (duplicate.map((dataa) => (<div key={dataa}>Duplicate email are: {dataa}</div>)))}
            {add === "bulk" && (
                <div className="dark:bg-slate-900 pt-10 mb-10">
                    <div className="flex flex-col pt-10 items-center min-h-screen w-full bg-white rounded-lg">
                        <div className="rounded-lg p-8 w-full sm:w-2/3 lg:w-1/2">
                            <div className="text-black mb-4 text-lg">Bulk Invite Users</div>
                            <label htmlFor="asd" className='bulk-input'>
                                <input type="file" onChange={handleFileChange} id="asd" wfd-id="id5" />
                            </label>
                            <button onClick={handleFileUpload} className="default-btn mt-4">Upload and Send Invites</button>
                            <button onClick={() => setAdd(false)} type="button" className="ml-2 default-btn cancel mt-4">
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {add === "single" && (
                <div className="dark:bg-slate-900 pt-10 mb-10">
                    <div className="flex flex-col pt-10 items-center min-h-screen w-full bg-white ">
                        <div className="rounded-lg p-8 w-full sm:w-2/3 lg:w-1/2">
                            <div className="pb-6 text-black mb-8 text-lg">Add New User</div>
                            <form >
                                <div className="mb-4">
                                    <label className="font-normal mb-2 text-black inline-block">
                                        First Name
                                    </label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        onChange={handleInputChange}
                                        className="bg-white autofill:bg-slate-800 relative block w-full appearance-none px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="First Name"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="font-normal mb-2 text-black inline-block">
                                        Last Name
                                    </label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        onChange={handleInputChange}
                                        className="bg-white autofill:bg-slate-800 relative block w-full appearance-none px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Last Name"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="font-normal mb-2 text-black inline-block">
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        name="email"
                                        onChange={handleInputChange}
                                        className="bg-white autofill:bg-slate-800 relative block w-full appearance-none px-3 py-2 text-gray-900 placeholder-gray-600 focus:z-10 focus:border-gray-400 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                        placeholder="Email"
                                        required
                                    />
                                </div>
                                <div className='mb-4'>

                                    <label className='font-normal mb-2 text-red-500 inline-block'>

                                        {msg}
                                    </label>
                                </div>
                                <div className="my-6 default-btn-outer">
                                    <button type="button" onClick={handleSubmit} className={ema ? "cursor-not-allowed default-btn" : "default-btn"}>

                                        Invite
                                    </button>
                                    <button onClick={() => setAdd(false)} type="button" className="default-btn cancel">
                                        CANCEL
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            {!add && (
                <div className="pt-10">
                    <div className="mb-2 md:mb-6 md:my-0">
                        <label className="inline-flex items-center cursor-pointer">
                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 mr-3">Active Users  </span>

                            <input
                                type="checkbox"
                                className="sr-only peer mx-2"
                                checked={isChecked}
                                onChange={handleToggle}
                            />
                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 peer-checked:after:right-0"></div>

                            <span className="ms-3 ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Invited Users </span>

                        </label>
                        <div className="flex justify-between items-center border-b border-blue-200 border-opacity-20 h-14 mb-8">
                            <div className="mb-1 text-4xl tracking-tight font-medium default-btn-outer ml-auto">
                                <button
                                    onClick={() => setAdd("single")}
                                    type="button"
                                    className="default-btn"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="w-5 h-5 pr-1"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    Add User
                                </button>
                                <button
                                    onClick={() => setAdd("bulk")}
                                    type="button"
                                    className="default-btn ml-2"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        className="w-5 h-5 pr-1"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                    Bulk Invite
                                </button>
                            </div>
                        </div>
                    </div>
                    {loading ? (
                        <div className="mb-6 mx-2 md:my-6 md:mx-10 rounded">
                            <Skeleton
                                className="mb-4"
                                baseColor="#ccc"
                                highlightColor="#fff"
                                height={140}
                                count={4}
                            />
                        </div>
                    ) : (
                        <div className="px-8 overflow-x-auto">
                            <table className="bg-white w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border-collapse rounded-xl" cellPadding={0} cellSpacing={0}>
                                <thead className="text-xs text-gray-700 uppercase bg-[#FFF5F3] dark:text-gray-400 rounded-xl">
                                    <tr className="text-slate-900 border-0">
                                        <th scope="col" className="p-3 rounded-tl-xl">
                                            Profile Image
                                        </th>
                                        <th scope="col" className="p-3">
                                            Name
                                        </th>
                                        <th scope="col" className="p-3">
                                            Email
                                        </th>
                                        {datafor === "active" && <th scope="col" className="p-3 text-center">Age</th>}
                                        {datafor === "active" && <th scope="col" className="p-3 text-center">Weight</th>}
                                        <th scope="col" className="p-3 text-center">
                                            Status
                                        </th>
                                        <th scope="col" className="p-3 text-center rounded-tr-xl">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((dataa) => (
                                        // <tr className="text-slate-900 border-0 border-solid border-b-2 border-gray-300 last:border-b-0" key={dataa._id}>
                                        <tr class={dataa?.email!==compemail?"text-slate-900 border-0 border-solid border-b-2 border-gray-300 last:border-b-0 "
                                            :
                                            "bg-slate-100 text-slate-900 border-0 border-solid border-b-2 border-gray-300 last:border-b-0"}
                                             key={dataa._id}>
                                            <td className="p-3">
                                                <img
                                                    src={profileImages[dataa._id] || adminDumny}
                                                    alt="Profile"
                                                    className="h-10 w-10 rounded-full"
                                                />
                                            </td>
                                            <td className="p-3">
                                                {dataa?.firstName} {dataa?.lastName}
                                            </td>
                                            <td className="p-3 max-w-4 w-1/4 whitespace-nowrap overflow-hidden text-ellipsis ...">
                                                {dataa?.email}
                                            </td>
                                            {datafor === "active" && <td className="p-3 text-center">{dataa?.age}</td>}
                                            {datafor === "active" && <td className="p-3 text-center">{dataa?.weight}</td>}
                                            {datafor === "active" && <td className="p-3 text-center">{dataa?.isActive === false ? "Inactive" : "Active"}</td>}
                                            {datafor === "Inactive" && <td className="p-3 text-center">Pending</td>}
                                            <td className="p-3 text-center">
                                                <div className="flex justify-center">
                                                    <div
                                                        onClick={() => setShowUserDetail(dataa)}
                                                        className="md:w-[100%] text-gray-600 mx-2 flex items-right justify-end"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="h-5 w-5"
                                                            viewBox="0 0 20 20"
                                                            fill="currentColor"
                                                        >
                                                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                        </svg>
                                                    </div>
                                              {dataa?.email!== compemail &&       <div
                                                        onClick={() => { setDeletePopUp(true); setDelUser(dataa._id); setNameForPopup(dataa.firstName) }}
                                                        className="md:w-[100%] text-gray-600 mx-2 flex items-right"
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 448 512"
                                                            className="w-4 h-4 mx-2"
                                                            fill="currentColor"
                                                        >
                                                            <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
                                                        </svg>
                                                    </div> }
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            )}
            {showUserDetail && (
                <CompUserEdit
                    forumData={showUserDetail}
                    goBack={() => setShowUserDetail("")}
                    getUsersList={() => getallActiveUser(companyId)}
                />
            )}
        </div>
    );
};

export default AllCompUser;

