// import React, { useState } from 'react';
// import { RadialBarChart, RadialBar, Legend, Tooltip } from 'recharts';

// const RadialChartCustom = ({ data, centerText }) => {
//     const [hiddenSectors, setHiddenSectors] = useState({});
// const handleLegendClick = (dataKey) => {
//     setHiddenSectors((prevState) => ({
//         ...prevState,
//         [dataKey]: !prevState[dataKey],
//     }));
// };

// const processedData = data.map((item) => ({
//     name: item.name,
//     uv: item?.value,
//     fill: item.itemStyle.color,
// }));

// const legendPayload = processedData.map((item) => ({
//     value: item.name,
//     type: 'square',
//     color: item.fill,
//     id: item.name,
// }));
// const style = {
//     bottom: -20,
//     left: 30,
//     lineHeight: "24px",
// };


// return (
//     <RadialBarChart
//         width={300}
//         height={300} 
//         cx={150}
//         cy={120}
//         innerRadius={data.length==2?70:50}
//         outerRadius={100}
//         barSize={20}
//         data={processedData.filter(item => !hiddenSectors[item.name])}
//     >
//         <RadialBar
//             minAngle={15}
//             // label={{ position: 'insideStart', fill: '#fff' }}
//             background
//             clockWise
//             dataKey="uv"
//         />
//         <Legend
//            iconSize={10}
//            width={250} 
//            height={100}
//            layout="horizontal"
//            verticalAlign="bottom" 
//            wrapperStyle={style}
//             payload={legendPayload}
//             onClick={(e) => handleLegendClick(e.id)}
//         />
//         <Tooltip />
//         <text
//             x={150}
//             y={280}
//             textAnchor="middle"
//             dominantBaseline="middle"
//             className="center-text"
//             style={{ fill: 'black', fontSize: 16 }}
//         >
//             {centerText}
//         </text>
//     </RadialBarChart>
// );
// };

// export default RadialChartCustom;

import React, { useState } from 'react';
import { RadialBarChart, RadialBar, Legend, Tooltip } from 'recharts';
const RadialChartCustom = ({ data, centerText }) => {
    const [hiddenSectors, setHiddenSectors] = useState({});
 
    
    const handleLegendClick = (dataKey) => {
        setHiddenSectors((prevState) => ({
            ...prevState,
            [dataKey]: !prevState[dataKey],
        }));
    };
 
    const processedData = data.map((item) => ({
        name: item.name,
        uv: item?.value,
        fill: item.itemStyle.color,
    }));
 
    const legendPayload = processedData.map((item) => ({
        value: item.name,
        type: 'square',
        color: item.fill,
        id: item.name,
        className: hiddenSectors[item.name] ? 'line-through' : '', 
    }));
 
    const style = {
        bottom: -20,
        left: 30,
        lineHeight: "24px",
    };
    return (
<RadialBarChart
            width={300}
            height={300}
            cx={150}
            cy={120}
            innerRadius={data.length === 2 ? 70 : 50}
            outerRadius={100}
            barSize={20}
            data={processedData.filter(item => !hiddenSectors[item.name])} // Filter hidden sectors
>
<RadialBar
                minAngle={15}
                background
                clockWise
                dataKey="uv"
            />
<Legend
                iconSize={10}
                width={250}
                height={100}
                layout="horizontal"
                verticalAlign="bottom"
                wrapperStyle={style}
                payload={legendPayload}
                onClick={(e) => handleLegendClick(e.id)}
                formatter={(value, entry) => (
<span className={hiddenSectors[entry.id] ? 'line-through' : ''}>
                        {value}
</span>
                )}
            />
<Tooltip />
<text
                x={150}
                y={280}
                textAnchor="middle"
                dominantBaseline="middle"
                className="center-text"
                style={{ fill: 'black', fontSize: 16 }}
>
                {centerText}
</text>
</RadialBarChart>
    );
};
export default RadialChartCustom;
