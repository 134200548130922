import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import Navbar from './Navbar/Navbar';
import SideBar from './SideBar/SideBar';
import Footer from "./Footer/Footer";
import Device from '../page/devices';
import EditProfilebyUsrPopUp from '../page/Popup/EditProfilebyUsrPopUp';
import Forums from '../page/forums';
import Calendar from '../components/calendar';
import SummaryGoals from "../page/summaryGoals";
import Leaderboard from '../page/leaderboard/leaderboard';
import HealthyContent from '../page/healthyContent';
import TrainingSlots from '../page/trainingSlots/trainingSlots';
import PersonalTraining from '../page/personalTraining/PersonalTraining';
import Membership from '../page/membership';
import AddUnavailableSlots from '../page/trainingSlots/addUnavailableSlots';
import CompanyReport from "../page/companyReport/companyReport";
import UserList from '../page/userList';
import Coupons from '../page/coupons';
import WellnessProgram from '../page/wellnessProgram/wellnessProgram';
import ForumAdd from "../page/forumAdd";
import CompanyAddForm from '../page/wellnessProgram/companyAddForm';
import CompanyEmployee from '../page/wellnessProgram/companyEmployee';
import BookSessions from '../page/personalTraining/BookSession';
import SessionHistory from '../page/personalTraining/sessionHistory';
import ForumsView from "../page/forumsUser";
import MemberShipManaged from '../page/memberShipManage';
import TrainingAmount from '../page/trainingAmount';
import PaymentConfirmationPage from '../page/personalTraining/paymentConfirmationPage';
import PaymentConfirm from '../page/membership/PaymentConfirm';
import AllCompUser from '../page/wellnessProgram/allCompUser';
import Reportcompany from '../page/wellnessProgram/companyReport';
import BookedTrainingSlots from '../page/trainingSlots/BookedTrainingSlots';
const ParentComponent = () => {
  const [open, setOpen] = useState();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [walkThroughPending, setWalkThroughPending] = useState(false);
  const [walkThroughSteps, setWalkThroughSteps] = useState('0');
  const[fullUrl,setFullUrl]=useState();
  const [userType, setUserType] = useState("");
  const [navname, setNavname] = useState('dashboard');
  const navigate = useNavigate();
  const location = useLocation();
  const [oldComp, setOldComp] = useState();
  const [fullPath, setFullPath] = useState('');
  const [paymentData, setPaymentData] = useState({
    paymentIntent: '',
    paymentIntentClientSecret: '',
    redirectStatus: ''
  });

  const getFullPathFromCurrentUrl = () => {
    const url = window.location.href.split('?')[0];
    const segments = url.split('/');
    const lastIndex = segments.length - 1;
    return segments.slice(3, lastIndex + 1).join('/');
  };

  const navValue = (val) => {
    setOldComp(open);
    setOpen(val);
    setNavname(" User Profile ")
  }

  const onNavigate = (currLocation) => {
    navigate(currLocation);
  }

  const parentToChild = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handlePopUp = (val) => {
    setOpen(oldComp);
  }

  const handleAddForums = (val) => {
    setOpen(val);
  }

  const cancelFor = (val) => {
    setOpen(val);
  }

  const avalibility = (vl) => {
    setOpen(vl);
  }

  const back = (val) => {
    setOpen(val);
  }

  const parentToChildSideBAr = (val) => {
    setNavname(val);
    switch (val || fullPath || fullPath.toLowerCase()) {
      case 'device':
        setOpen(0);
        navigate('/device')
        break;
      case 'dashboard':
        setOpen(2);
        navigate('/dashboard')
        break;
      case 'summary-goals':
        setOpen(3)
        navigate('/summary-goals')
        break;
      case 'leaderboard':
        setOpen(4);
        navigate('/leaderboard')
        break;
      case 'healthyContent':
        setOpen(5);
        navigate('/healthycontent')
        break;
      case 'forums':
        setOpen(6);
        navigate('/forums')
        break;
      case 'Managed/MamberShip':
        setOpen(21);
        navigate('/Managed/MamberShip')
        break;
      case 'Managed/TrainingSession':
        setOpen(22);
        navigate('Managed/TrainingSession')
        break;
      case 'membership':
        setOpen(7);
        navigate('/membership')
        break;
      case 'Training':
        setOpen(8);
        navigate('/Training')
        break;
      case 'training-slots':
        setOpen(9);
        navigate('/training-slots')
        break;
      case 'company/report':
        setOpen(10);
        navigate('/company/report')
        break;
      case 'users':
        setOpen(11);
        navigate('/users')
        break;
      case 'coupons':
        setOpen(12);
        navigate('/coupons')
        break;
      case 'forum':
        setOpen(20);
        navigate('/forums')
        break;
      case 'Company/Report':
        setOpen(26);
        navigate('/Company/Report')
        break;
      case 'wellnessprogram':
        setOpen(13);
        navigate('/wellnessprogram')
        break;
      case 'payment-confirm':
        setOpen(24);
        navigate('/payment-confirm')
        break;
      case 'wellnessprogram/invite-company':
        navigate("/wellnessprogram/invite-company"); setOpen(16);
        break;
      case 'training-slots/add':
        navigate("/training-slots/add"); setOpen(15);
        break;
      case 'booked/sessions':
        navigate("/booked/sessions"); setOpen(19);
        break;
      case 'services/book/slots':
        navigate("/services/book/slots"); setOpen(18);
        break;
      case 'forums/add':
        navigate("/forums/add"); setOpen(14);
        break;
      case 'Company/User':
        navigate("/Company/User"); setOpen(25);
        break;
      case 'Payment/Confirm':
        navigate("/services/pament/confirm"); setOpen(23);
        break;
      case 'BookedTrainingSlots':
        navigate("/BookedTrainingSlots"); setOpen(27);
        break;
      default:
        if(!val.includes("device/authenticate")){
          navigate('/device'); console.log("defaulttt");
          break;
        }
    }
  };

  useEffect(() => {
    let isLogin = localStorage.getItem('auth-token');
    if (!isLogin && location.pathname !== '/') {
      navigate('/');
    } else if (isLogin && location.pathname === '/') {
      navigate('/dashboard');
    }
    setWalkThroughPending(JSON.parse(localStorage.getItem('walkthrough'))?.walkThroughPending);
    setWalkThroughSteps(JSON.parse(localStorage.getItem('walkthrough'))?.walkThroughSteps);
    setUserType(localStorage.getItem('roles'));
  }, [location]);

  useEffect(() => {
    const detectTimeZone = () => {
      const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      localStorage.setItem("timeZone", detectedTimeZone);
    };

    detectTimeZone();
    const currentFullPath = getFullPathFromCurrentUrl();
    const handlePath = (path) => {
      parentToChildSideBAr(path);
      setFullPath(path);
      setNavname(path);
    };

    if (currentFullPath === "services/pament/confirm") {
      urlPaymentConfirm();
      parentToChildSideBAr("Payment/Confirm");
    } else {
      handlePath(currentFullPath);
    }

    if (currentFullPath === "wellnessprogram/invite-company" ||
      currentFullPath === "training-slots/add" ||
      currentFullPath === "forums/add" ||
      currentFullPath === "services/book/slots" ||
      currentFullPath === "booked/sessions") {
      handlePath(currentFullPath);
    }
  }, []);

  useEffect(() => {
    // Listen for URL changes and update `open` state accordingly
    const handleUrlChange = () => {
      const path = getFullPathFromCurrentUrl();
      parentToChildSideBAr(path);
    };

    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, []);

  const urlPaymentConfirm = () => {
    const url = window.location.href;
    const segments = url.split('/');
    const lastIndex = segments.length - 1;
    setFullUrl(segments.slice(3, lastIndex + 1).join('/'));

    const urlParams = new URLSearchParams(window.location.search);
    setPaymentData({
      paymentIntent: urlParams.get('payment_intent') || '',
      paymentIntentClientSecret: urlParams.get('payment_intent_client_secret') || '',
      redirectStatus: urlParams.get('redirect_status') || ''
    });
  };

  let companyDetails = localStorage.getItem('company');
  if (companyDetails) {
    companyDetails = JSON.parse(companyDetails);
  }

  const isCompanyAdmin = ['company'].includes(localStorage.getItem('roles'));
  const isAdminRole = ['admin'].includes(localStorage.getItem('roles'));
  const isCompanyUser = localStorage.getItem('company');
  let userStatus = localStorage.getItem('status');
  const userInfo = {
    "walkThroughPending": walkThroughPending,
    "walkThroughSteps": walkThroughSteps,
    "isCompanyUser": isCompanyUser,
    "userStatus": userStatus,
    "companyDetails": companyDetails,
    "isAdminRole": isAdminRole,
    "isCompanyAdmin": isCompanyAdmin
  };
useEffect(()=>{
  console.log("login ----");
  let isLogin = localStorage.getItem('auth-token');
  console.log("login ",isLogin);
  if(!isLogin){
    window.location.href = '/login';
  }
},[])
  return (
    <div className="main">
      <div className="container">
        <SideBar isSidebarOpen={isSidebarOpen} parentToChildSideBAr={parentToChildSideBAr} userType={userType} howerRefresh={fullPath} userInfo={userInfo} />
        <div className="dashboard">
          <Navbar parentToChild={parentToChild} name={navname} navValue={navValue} open={open} />
          {open === 8 && <PersonalTraining back={back} />}
          {open === 0 && <Device />}
          {open === 13 && <WellnessProgram back={back} />}
          {open === 17 && <CompanyEmployee back={back} />}
          {open === 4 && <Leaderboard />}
          {open === 10 && <CompanyReport />}
          {open === 6 && <Forums handleAddForums={handleAddForums} />}
          {open === 3 && <SummaryGoals back={back}/>}
          {open === 9 && <TrainingSlots avalibility={avalibility} />}
          {open === 15 && <AddUnavailableSlots back={back} />}
          {open === 2 && <Calendar back={back}/>}
          {open === 5 && <HealthyContent />}
          {open === 18 && <BookSessions back={back} />}
          {open === 20 && <ForumsView back={back} />}
          {open === 11 && <UserList back={back} />}
          {open === 7 && <Membership />}
          {open === 19 && <SessionHistory back={back} />}
          {open === 1 && <EditProfilebyUsrPopUp handlePopUp={handlePopUp} popup={open} />}
          {open === 12 && <Coupons />}
          {open === 21 && <MemberShipManaged />}
          {open === 14 && <ForumAdd cancelFor={cancelFor} />}
          {open === 16 && <CompanyAddForm back={back} />}
          {open === 22 && <TrainingAmount />}
          {open === 23 && <PaymentConfirmationPage data={paymentData} back={back} />}
          {open === 24 && <PaymentConfirm back={back} />}
          {open === 25 && <AllCompUser back={back} />}
          {open === 26 && <Reportcompany back={back} />}
          {open === 27 && <BookedTrainingSlots back={back} />}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ParentComponent;
//last 27
