import React, { useEffect, useState } from "react";
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TrainingBuyCard = ({ data, amount ,ses}) => {
    const [arr, setArr] = useState([]);

    useEffect(() => {
        console.log("data",data)
        setArr(data);
    }, [data]);

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 5000,
        autoplaySpeed: 5000,
        cssEase: "linear"
    };

    return (
        //   <Slider {...settings}>
        <div className="flex flex-wrap gap-4 col-package-section">

            {arr?.map((data) => {
                if (!data?.isActive) return null;
                
                return (
                    <div key={data.id}  onClick={() => {amount(data?.price);ses(data?.session)}} className="flex flex-col py-6 px-3 mx-0 my-2 w-[200px] text-black bg-white hover:bg-[#FFF8F6] border-2 border-solid border-[#1818181a] rounded-lg hover:border-[#E53E14] transition transition-all transition-colors col-package">
                        <div className="flex items-baseline">
                            <span className="text-sm bg-[#E53E14] text-white py-2 px-4 rounded-lg title-box">{data?.title}</span>
                        </div>
                        
                        <div className="flex items-baseline mt-5 mb-6">
                            <span className="text-base ">{data?.description}</span>
                        </div>
                        <div className="flex items-baseline mt-1 mb-3">
                            <h2 className="text-base tracking-tight font-medium text-stone-900 dark:text-white mb-1"><span className="text-2xl font-bold">${data?.price}</span>/ {data?.session} Session</h2>
                        </div>
                        <div
                            onClick={() => {amount(data?.price);ses(data?.session)}}
                            className={data.isActive === true ? `p-2 2xl:p-3 rounded-lg text-white cursor-pointer text-center mt-5 bg-white hover:bg-[#E53E14] text-[#e9ab9e] hover:text-white border border-solid border-[#e9ab9e] transition-all transition-colors buy-button` : `bg-[#E53E14] p-2 2xl:p-3 rounded-lg text-white cursor-not-allowed opacity-50`}
                        >
                            <ShoppingCartIcon className="h-5 w-5 mr-2 align-middle font-bold" />
                            Buy Now
                            {false && (
                                <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-3 animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                                </svg>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    //   </Slider>  
    );
}

export default TrainingBuyCard;


{/* <Slider {...settings}>


      {arr?.map((data) => {
          if (!data?.isActive) return null;
          
          return (
              <div key={data.id} className="flex flex-col gap-6 p-2 sm:p-3 xl:p-4 mx-0 my-2 w-1/5 text-center text-black bg-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                  <div className="flex justify-center items-baseline mt-8 mb-4">
                      <span className="text-4xl font-bold">$ {data?.price}</span>
                  </div>
                  <h2 className="text-xl tracking-tight font-medium text-stone-900 dark:text-white mb-12">{data?.session} SESSION</h2>
                  <span
                      onClick={() => {amount(data?.price);ses(data?.session)}}
                      className={data.isActive === true ? `bg-[#E53E14] p-2 2xl:p-3 rounded-lg text-white cursor-pointer mb-8` : `bg-[#E53E14] p-2 2xl:p-3 rounded-lg text-white cursor-not-allowed opacity-50 mb-8`}
                  >
                      <ShoppingCartIcon className="h-5 w-5 text-white mr-2 align-middle" />
                      Buy Now
                      {false && (
                          <svg aria-hidden="true" role="status" className="inline w-4 h-4 ml-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                          </svg>
                      )}
                  </span>
              </div>
          );
      })}
   
</Slider> */}
