import { React, useEffect, useState } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import "./SignIn.css"
import { BASE_URL } from "../../config"
import axios from "axios"
import Logo from "../../assets/images/cftLogo.svg"
import googleIcon from "../../assets/images/googleIcon.svg"
import fbIcon from "../../assets/images/fbIcon.svg"
import loginImg from "../../assets/images/loginImg.png"

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [resStatus,setResStatus]=useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleLoginSubmit = (event) => {
    setEmail("");
    setLoading(true);
    axios({
      url: `${BASE_URL}/auth/signin`,
      method: "POST",
      data: { email, password },
    }).then((res) => { 
      setLoading(false);
  setResStatus(res?.status);   
  let data = res?.data?.data;
  localStorage.setItem('walkthough', JSON.stringify({ walkThroughPending: data?.walkThroughPending, walkThroughSteps: data?.walkThroughSteps }));
      localStorage.setItem('auth-token', data?.accessToken);
      localStorage.setItem('company', data?.company ? JSON.stringify(data?.company) : "");
      localStorage.setItem('user', JSON.stringify({ price: data?.price, userCount: data.userCount, userRange: data?.userRange, expectedCftUser: data?.expectedCftUser }));
      localStorage.setItem('roles', data?.roles);
      localStorage.setItem('user-name', (data?.firstName || data?.companyName) + ' ' + (data?.lastName || ''));
      localStorage.setItem('email', data?.email);
      localStorage.setItem('subscribedStatus', data?.subscribedStatu || false);
      localStorage.setItem('planType', data?.planType || '');
      localStorage.setItem('coupon', data?.coupon || '');
      localStorage.setItem('status', data?.status || '');
      localStorage.setItem('personInCharge', data?.personInCharge || '');

      setTimeout(() => {
        if (data?.walkThroughPending && data?.walkThroughSteps === '0') {
          if (data?.roles === 'user' && data?.company?.email) {
            navigate('/device');
          } else {
            if((!data.hasOwnProperty('personInCharge')) || data.personInCharge.trim() == ''){
                navigate('/membership');
            }else{
              // navigate('/dashboard');
              navigate('/device');
            }

          }
        } else if (data?.walkThroughPending && data?.walkThroughSteps === '1') {
          navigate('/device');
        } else {
          // navigate('/dashboard');
          navigate('/device');
        }
      }, 200);
    }).catch((err) => {
      if (err?.response?.data?.message); {
        setError(err?.response?.data?.message);
        setResStatus(err?.response?.status)
        setTimeout(() => { setError("") }, 3000);
      } setLoading(false);
    })
  };
  useEffect(() => {
    let isLogin = localStorage.getItem('auth-token');
    if (!isLogin && !(location.pathname === '/' || location.pathname === '/sign-up')) {
      navigate('/');
    } else if (isLogin && location.pathname === "/") {
      navigate('/device');
    }
  }, [location]);
  return (
    <>
      <div className="sign-in-page sign-in-default sign-in-default-v1">
        {/* <div className="bg-icons" /> */}
        <div className="container">
          <div className="form-box-outer">
            <div className="content-block">
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>
              <h1>Log in to</h1>
              <p>CFT is a fitness tracker-based App that provides all the necessary components to help individuals and employees become healthy.</p>
            </div>
            <div className="form-box">
              <h2>Login</h2>
              <div className="input-box">
                <label>Email Address</label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  placeholder="Email address"
                />
                {resStatus===404&&( <span className="error">{error}</span>)}
               
              </div>
              <div className="input-box">
                <label>Password</label>
                <div className="relative">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    type={!showPassword?'password':"text"}
                    autoComplete="current-password"
                    required
                    placeholder="Password"
                  />
                  <span className="input-icon" onClick={()=>{setShowPassword(!showPassword)}} >
                    {!showPassword?<EyeSlashIcon class="h-5 w-5 text-gray-300" />: <EyeIcon class="h-5 w-5 text-gray-300" />}
                  </span>
                </div>
                {resStatus===401&&( <span className="error">{error}</span>)}
              </div>
              <div className="link-box">
                <Link to="/forgot/password">Forgot Passowrd ?</Link>
              </div>
              <div className="submit-box">
                <button onClick={() => handleLoginSubmit()} type="button" className="">
                  {loading ? (<div className="lds-facebook"><div></div><div></div><div></div><div></div><div></div></div>) : "Login"}
                </button>
              </div>
              {/* <div className="continue-line">or continue with</div>
              <div className="social-media">
                <Link to="">
                  <img src={fbIcon} alt="Icon" />
                </Link>
                <Link to="/">
                  <img src={googleIcon} alt="Icon" />
                </Link>
              </div> */}
              <div className="bottom-text text-center">
                Don’t have an account ? <Link to="/sign-up">Signup  </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
}
export default SignIn;