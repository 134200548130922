import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import DeletePopUp from "../../components/deletePopUp";

const BookedTrainingSlots = () => {
  const [isDelete, setIsDelete] = useState(false);
   const [slot,setSlot]=useState();
   const [remData,setRemData]=useState();
   const [datafor, setDatafor] = useState("all");
   const[dataa,setDataa]=useState();
    const [isChecked, setIsChecked] = useState(false);
  const fetchbookedSlot = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/allbookedSession`, {
        headers: {
          "x-access-token": localStorage.getItem("auth-token"),
        },
      });
      setSlot(response?.data?.data);
      cancelreqDate(response?.data?.data)
    } catch (error) {
      console.error("Error fetching training plans:", error);
    }
  };
     let arr=[];
     const cancelreqDate =(data)=>{
    data.forEach(element => {
        if(element.book?.cancelReq===true){
            arr.push(element)
        }
    }
);
setDataa(arr)
}
  const deleteSession = async(formData,user) => {
    try {
       const response = await axios.post(`${BASE_URL}/admin/training/delete`, {id:formData,sesId:user}, {
           headers: {
             "x-access-token": localStorage.getItem("auth-token"),
           },
       }); 
       if (response.data.success) {
           setIsDelete(false);
           fetchbookedSlot();
         }
       } catch (error) {
         console.error("Error adding Training package package:", error);
       }
     };
 
  const yes = () => {
    deleteSession(remData?.book?._id,remData?.sesId)
    // handleDelete(titId);  allbookedSession
  };

  const cancel = () => {
    setIsDelete(false);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    
    return `${day}-${month}-${year}`;
  };
  const handleToggle = () => {
    setIsChecked(!isChecked);
    setDatafor(isChecked ? "all" : "requested");
};

useEffect(()=>{fetchbookedSlot()},[])

  return (
    <div className="dark:bg-slate-900 md:mx-10 min-h-screen pb-4">
      {isDelete && <DeletePopUp data={`Remove ${remData?.book?.time?.startTime} to ${remData?.book?.time?.endTime} slot of ${formatDate(remData?.book?.date)} to ${remData?.email}  user Name ${remData?.name}!`} yes={yes} cancel={cancel} />}
      
        <div className="pt-10">
        <label className="inline-flex items-center cursor-pointer">
                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300 mr-3">All Training Session  </span>

                            <input
                                type="checkbox"
                                className="sr-only peer mx-2"
                                checked={isChecked}
                                onChange={handleToggle}
                            />
                            <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 peer-checked:after:right-0"></div>

                            <span className="ms-3 ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Cancel Requested</span>

                        </label>
                        {datafor==="all" && <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-900 border-collapse bg-[#fff]" cellPadding={0} cellSpacing={0}>
            <thead className="text-xs text-gray-700 uppercase bg-[#FFF5F3] dark:text-gray-900">
              <tr className="text-slate-900 border-0">
                <th scope="col" className="px-6 py-3">
                  User Name
                </th>
                <th scope="col" className="px-6 py-3">
                Email
                </th>
               
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Start Time
                </th>
               
                <th scope="col" className="px-6 py-3">
                  End Time
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Status
                </th>
              </tr>
            </thead>
            
            <tbody>
              {slot?.map((data) => (
                <tr className={data.book.cancelReq ? `text-slate-900 border-0 border-solid border-b-2 border-gray-300 last:border-b-0 bg-slate-100`:
                `text-slate-900 border-0 border-solid border-b-2 border-gray-300 last:border-b-0`} key={data.sesId}>
                  <td className="px-6 py-4">{data?.name}</td>
                  <td className="px-6 py-4">{data?.email}</td>
                  <td className="px-6 py-4">{formatDate(data?.book?.date)}</td>
                  <td className="px-6 py-4">{data?.book?.time?.startTime}</td>
                  <td className="px-6 py-4">{data?.book?.time?.endTime}</td>
                  <td className="px-6 py-4">
                    <div className="flex justify-center">
                    
                     {data.book.cancelByAdmin?<div className="pointer-events-none"><button className='bg-[#27a500] border-0 p-2 text-white rounded'> Canceled</button></div>: <div className="text-gray-900 mx-2 flex" onClick={() => {setIsDelete(true); setRemData(data) }}>
                     <button className='bg-[#186CED] border-0 text-white p-2 px-3 rounded'>cancel </button>
                      </div>}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>}
          {datafor=="requested"&& <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-900 border-collapse bg-[#fff]" cellPadding={0} cellSpacing={0}>
            <thead className="text-xs text-gray-700 uppercase bg-[#FFF5F3] dark:text-gray-900">
              <tr className="text-slate-900 border-0">
                <th scope="col" className="px-6 py-3">
                  User Name
                </th>
                <th scope="col" className="px-6 py-3">
                Email
                </th>
               
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Start Time
                </th>
               
                <th scope="col" className="px-6 py-3">
                  End Time
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Action
                </th>
              </tr>
            </thead>
            
            <tbody>
              {dataa?.map((data) => (
                <tr className={data.book.cancelReq ? `text-slate-900 border-0 border-solid border-b-2 border-gray-300 last:border-b-0 bg-slate-100`:`text-slate-900 border-0 border-solid border-b-2 border-gray-300 last:border-b-0`} key={data.sesId}>
                  <td className="px-6 py-4">{data?.name}</td>
                  <td className="px-6 py-4">{data?.email}</td>
                  <td className="px-6 py-4">{formatDate(data?.book?.date)}</td>
                  <td className="px-6 py-4">{data?.book?.time?.startTime}</td>
                  <td className="px-6 py-4">{data?.book?.time?.endTime}</td>
                  <td className="px-6 py-4">
                    <div className="flex justify-center">
                    
                    {data.book.cancelByAdmin?<div className="pointer-events-none"><button className='bg-[#27a500] border-0 p-2 text-white rounded'> Canceled</button></div>: <div className="text-gray-900 mx-2 flex" onClick={() => {setIsDelete(true); setRemData(data) }}>
                    <button className='bg-[#186CED] border-0 text-white p-2 px-3 rounded'>cancel </button>
                      </div>}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>}
          
        </div>
    
    </div>
  );
};

export default BookedTrainingSlots;
